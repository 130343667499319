<template>
  <v-container>
    <v-dialog close-delay="1" class="rounded-xl" width="25%" style="height: 20vw;"  v-model="dialog">
      <v-container style="background:#FADA31">
        <v-row justify="center">
            <v-col class="text-center">
                <v-icon style="font-size: 7vw;color:#022D41" class="mt-10">mdi-emoticon-sad-outline</v-icon>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <p style="font-size:1vw;color:#022D41" class="text-center title">No hemos podido enviar tu postulación debes completar tu cv</p>
            </v-col>
        </v-row>
      </v-container>
      
      <v-container style="background:#022D41">
          <v-row>
              <v-col class="text-center">
                  <p style="color:#D3E02F;font-size:2vw">Completar CV</p>
              </v-col>
          </v-row>
      </v-container>
    </v-dialog>

    <v-btn
    tile
    style="background:#EFF3F2;font-size: 0.7vw"
    width="11vw"
    height="80"
    @click="dialog = true"
    >
    <div class="d-flex flex-column">
    <v-icon style="font-size:4vh">mdi-file-document-outline</v-icon>
    <space>Enviar Postulación</space>
    </div>
    </v-btn>
  </v-container>
</template>


<script>
export default {
    data(){
        return{
            dialog:false
        }
    }

}
</script>

<style>

</style>