<template>
  <v-container fluid style="background:#eff3f2;">
      <v-row>
          <v-col class="text-center" cols="12" sm="6" >
              <div class="display-1">Mis Ofertas</div>
          </v-col>
          <v-col class="text-center" cols="12" sm="6">
              <Nuevaoferta/>
          </v-col>
      </v-row>
          <v-row justify="center">
              <v-col cols="12" sm="12">
                  <v-card class="mt-5">
                      <v-row>
                          <v-col cols="8" sm="8">
                            <div style="color:#022D41" class="ml-10 mt-5">
                              <div class="title mb-2">Arquitectos y Asociados</div>
                              <div class="subtitle-2 mb-2">Se busca carpintero con experiencia</div>
                              <div class="subtitle-2 mb-2">Comuna: Las Condes, RM</div>
                              <div class="subtitle-2">Fecha: 20/12/20</div>
                            </div> 
                          </v-col>
                          <v-col cols="4" sm="4">
                              <v-row>
                                  <v-col>
                                <div class="mr-5 mt-2 text-end">
                                  <v-btn
                                  style="background:#EFF3F2"
                                  width="100"
                                  height="50"
                                  >
                                  <div class="d-flex flex-column">
                                  <v-icon>mdi-file-document-outline</v-icon>
                                   <span class="subtitle-2">Editar</span> 
                                   </div>
                                   </v-btn>
                                </div>
                                  </v-col>
                              </v-row>
                              <v-row>
                                  <v-col>
                                    <div class="mr-5 mt-2 text-end">
                                    <v-btn  
                                    style="background:#FD2A22;color:white"
                                    width="100"
                                    height="50"
                                    >
                                    <div class="d-flex flex-column">
                                    <v-icon>mdi-trash-can-outline</v-icon>
                                     <span class="subtile-2">Eliminar</span> 
                                     </div>
                                     </v-btn>
                                    </div> 
                                  </v-col>
                              </v-row>
                          </v-col>
                      </v-row>
                  </v-card>

                  <v-card class="mt-5">
                      <v-row>
                          <v-col cols="8" sm="8">
                            <div style="color:#022D41" class="ml-10 mt-5">
                              <div class="title mb-2">Arquitectos y Asociados</div>
                              <div class="subtitle-2 mb-2">Se busca carpintero con experiencia</div>
                              <div class="subtitle-2 mb-2">Comuna: Las Condes, RM</div>
                              <div class="subtitle-2">Fecha: 20/12/20</div>
                            </div> 
                          </v-col>
                          <v-col cols="4" sm="4">
                              <v-row>
                                  <v-col>
                                <div class="mr-5 mt-2 text-end">
                                  <v-btn
                                  style="background:#EFF3F2"
                                  width="100"
                                  height="50"
                                  >
                                  <div class="d-flex flex-column">
                                  <v-icon>mdi-file-document-outline</v-icon>
                                   <span class="subtitle-2">Editar</span> 
                                   </div>
                                   </v-btn>
                                </div>
                                  </v-col>
                              </v-row>
                              <v-row>
                                  <v-col>
                                    <div class="mr-5 mt-2 text-end">
                                    <v-btn  
                                    style="background:#FD2A22;color:white"
                                    width="100"
                                    height="50"
                                    >
                                    <div class="d-flex flex-column">
                                    <v-icon>mdi-trash-can-outline</v-icon>
                                     <span class="subtile-2">Eliminar</span> 
                                     </div></v-btn>
                                    </div> 
                                  </v-col>
                              </v-row>
                          </v-col>
                      </v-row>
                  </v-card>
              </v-col>
          </v-row>
  </v-container>
</template>

<script>
import Nuevaoferta from '../Cliente/Nuevaoferta'
export default {
    components:{Nuevaoferta}

}
</script>

<style>

</style>