<template>
  <v-container fluid style="background:#FFFFFF;border:solid 0.5px;border-color:#d6d1cb;">
      <v-row class=" d-flex flex-nowrap">
          <v-col class="text-center" cols="1">
            <v-avatar>
                <img
                    
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Coca-Cola_bottle_cap.svg/163px-Coca-Cola_bottle_cap.svg.png"
                    alt="Coca-Cola"
                >
            </v-avatar>
          </v-col>
          <v-col cols="">
              <div class="mt-2">
              <div class="title">Coca Cola</div>
              </div>
          </v-col>

          <v-col class="text-end">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn class="mt-2" rounded style="background:#D3E02F" >
                        <v-icon color="black" dark v-on="on">mdi-calendar-range-outline</v-icon>
                        </v-btn>
                    </template>
                    <span>Agendar Entrevista</span>
                </v-tooltip>
              <!-- <div> -->
              <!-- <v-btn  small width="150" class="mt-n1" :height="!$vuetify.breakpoint.mobile ? '50':'50'"  text outlined rounded>
                <div class="d-flex flex-column">
                    <v-icon class="pt-2">mdi-calendar-range-outline</v-icon> -->
                    <!-- <p style="font-size:90%">Agendar entrevista</p>  -->
                <!-- </div>
              </v-btn>
              </div> -->
          </v-col>
          <v-col>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn class="mt-2" style="background:#D3E02F" rounded>
                        <v-icon color="black" dark v-on="on">mdi-video</v-icon>
                        </v-btn>
                    </template>
                    <span>Iniciar Video Llamada</span>
                </v-tooltip>              
              <!-- <div>
                  <v-btn small width="160" class="mt-n1" :height="!$vuetify.breakpoint.mobile ? '50':'50'" style="background:#D3E02F" text outlined rounded>
                    <div class="d-flex flex-column">
                    <v-icon class="pt-2">mdi-video</v-icon>
                    <p style="font-size:90%">Iniciar video llamda</p>
                    </div>
                  </v-btn>
              </div> -->
          </v-col>
      </v-row>
  </v-container>
</template>

<script>
export default {

}
</script>

<style>

</style>