import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/components/Login/Login'
import Home from '@/components/Home/Home'
import Panel from '@/components/Panel'
import PreLogin from '@/components/Modals/PreLogin/PreLogin'
import NuevoLogin from '@/components/Modals/NuevoLogin/NuevoLogin.vue'
// import NuevoRegistro from '@/components/Modals/NuevoLogin/NuevoRegistro'
import Register from '@/components/Modals/Register'


import store from '../Stores/stores.js'

Vue.use(Router)

const router = new Router({
	routes: [
		{
			path: '/login',
			name: 'Login',
			component: Login
		},
		{
			path: '/',
			name: 'Home',
			component: Home
		},
		{
			path: '/panel',
			name: 'Panel',
			component: Panel
		},
		{	
			path: '/PreLogin',
			name: 'PreLogin',
			component: PreLogin
		},
		{
			path: '/NuevoLogin',
			name: 'NuevoLogin',
			component: NuevoLogin
		},

		// {
		// 	path: '/NuevoRegistro',
		// 	name: 'NuevoRegistro',
		// 	component: NuevoRegistro
		// },

		{
			path: '/Register',
			name: 'Register',
			component: Register
		},		
	]
})

router.beforeEach((to, from, next) => {
	if (to.name !== 'login' && to.name !== 'Register' && to.name !== 'Home' && !store.state.isAuthenticated) next({ name: 'login' })
	else if (to.name === 'login' && store.state.isAuthenticated) next({ name: 'Home' })
	else next()
})

export default router
