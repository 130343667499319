<template>
  <div class="fill-height dflex" style="flex: 1">
		<v-container
			class="dflex flex-nowrap align-start pt-0 pb-1 px-0"
			fill-height
			style="background-color: #EFF3F2;"
			fluid>
      <PanelDrawer/>
      <Navegacion style="flex: 1;"/>
    </v-container>
  </div>
</template>


<script>
import PanelDrawer from './PanelDrawer'
import Navegacion from './Navegacion'
export default {
    components:{Navegacion,PanelDrawer}

}
</script>

<style>

</style>