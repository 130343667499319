<template>
  <v-app>
    <Wraper />
    <ModalIndex />
  </v-app>
</template>

<script>
import Wraper from './components/Layout/Wraper'
import ModalIndex from './components/Modals/Index'
import {getUser} from './Helpers/api/user'
import { mapGetters } from "vuex"
import firebase from 'firebase/app'
import 'firebase/database'

export default {
  name: 'App',
  components: { Wraper, ModalIndex },
  computed: {
    ...mapGetters(["userData"])
  },
  watch:{
    userData: function(){
      if(!this.$store.state.primaryDB){
        this.initFirebase()
      }
    }
  },
  data: () => ({
    
    //
  }),
  mounted(){
    const token = localStorage.getItem('userToken')
    if(token) { getUser() }
  },
  methods: {
    async initFirebase(){
      const firebaseConfig = {
        apiKey: "AIzaSyBObaqPuI_LrG2_R1VL96BKxIh1C8q_i6k",
        authDomain: "supega-nph.firebaseapp.com",
        databaseURL: "https://supega-nph.firebaseio.com",
        projectId: "supega-nph",
        storageBucket: "supega-nph.appspot.com",
        messagingSenderId: "564843680530",
        appId: "1:564843680530:web:cf452b20e0d15d045599cb",
        measurementId: "G-FLN6P9YNEC"
      };
      const primaryDB = await firebase.initializeApp(firebaseConfig, 'primaryDB')
      await this.$store.commit('setPrimaryDB', primaryDB)

      //ID Sala: IDusuarioEmpleador-IdUsuarioProfesional
      
      // Con la siguiente línea puedes escribir en la base de datos primaria en cualquier parte del proyecto (Supega)
      // Donde lo quieras usar tienes que importar los módulos de firebase y database (línea 13 y 14 de este componente)
      firebase.database(this.$store.state.primaryDB).ref('TestSupega').set({testConnection: true})
      
    }
  }
};
</script>

