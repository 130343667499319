<template>
<div class="fill-height dflex">
  <v-container  			
      class="dflex flex-nowrap align-start pt-0 pb-0 px-0"
			fill-height
			style="background-color: #EFF3F2;"
			fluid>
      <Lateral/>
      <Todos />


  </v-container>
 </div> 
</template>

<script>

import Todos from './PartesChat/Todos'
import Lateral from './PartesChat/Lateral'
// import Footer from './PartesChat/Footer'
// import Body from './PartesChat/Body'
// import Header from '../Chat/PartesChat/Header'
export default {
    components:{Lateral,Todos}

}
</script>

<style>

</style>