<template>
  <v-container fluid style="background:#eff3f2">
      <v-row justify="center">
          <v-col cols="12" sm="12">
                    <v-card>
                      <v-row>
                          <v-col class=" text-center mt-1" cols="8">
                              <div class="title">Hazte Premiun</div>
                              <div class="body-1">Cancelando 5.000$ al mes</div>
                              <div class="body-1">Puedes salir en todas las Búsquedas de captadores</div>
                              <br>
                          </v-col>
                          <v-col class="text-center " cols="4">
                              <div  class="rounded-xl mx-auto" :style="!$vuetify.breakpoint.mobile ? 'width:45%;background:#2CBBCB':'width:80%;background:#2CBBCB'" >
                              <v-icon size="80" color="white">mdi-credit-card-outline</v-icon>
                              <p style="color:white" class="title">Pagar</p>
                              </div>
                          </v-col>
                      </v-row>
                </v-card>
          </v-col>
      </v-row>
        <v-row justify="center">
            <v-col cols="12" sm="12">
                    <v-card>
                      <v-row class="">
                          <v-col class="text-center mt-5" cols="4"  md="5">
                              <div :style="!$vuetify.breakpoint.mobile ? 'background:#FD7B22;width:30%;height:80%':'background:#FD7B22;width:90%;height:80%'" class=" mx-auto rounded-xl">
                              <v-icon  size="65" color="white">mdi-lock</v-icon>
                              </div>
                          </v-col>

                          <v-col class=" text-center mt-7" cols="4" md="2">
                              <div class="title">Contraseña</div>
                              <div class="body-1">**************</div>
                              <br>
                          </v-col>
                          <v-col class="text-center mt-5" cols="4" md="5">
                              <div :style="!$vuetify.breakpoint.mobile ? 'background:#D3E02F;width:30%;height:80%':'background:#D3E02F;width:90%;height:80%'" class=" mx-auto rounded-xl" >
                              <v-icon size="30" class="mt-1" color="#022D41">mdi-square-edit-outline</v-icon>
                              <p style="color:#022D41" class="title">Editar</p>
                              </div>
                          </v-col>

                      </v-row>
                </v-card>
          </v-col>
      </v-row>

      <v-row justify="center">
          <v-col cols="12" md="">
              <v-card>
                  <v-row>
                      <v-col class="text-center">
                          <div class="title mt-3 mb-3"><Editar/></div>
                      </v-col>
                  </v-row>
              </v-card>
          </v-col>

          <v-col cols="12" md="">
              <v-card>
                  <v-row>
                      <v-col cols="12" class="text-center">
                          <v-btn
                        class="rounded-pill mt-3 mb-3 "
                        width="200"
                        height="80"
						color="#022D41"
						text
						style="text-transform: none; background:#D3E02F"
						@click="logoutPanel"
					>
						<v-icon left dark>mdi-logout</v-icon>
					Salir
					</v-btn>
                      </v-col>
                  </v-row>
              </v-card>
          </v-col>

          <v-col cols="12" md="">
              <v-card>
                  <v-row>
                      <v-col>
                          <div class="title text-center mt-3 mb-3"><RegistroEmpleador/></div>
                      </v-col>
                  </v-row>
              </v-card>
          </v-col>

          <v-col cols="12" md="">
              <v-card>
                  <v-row>
                      <v-col class="text-center">
                          <v-btn width="200" height="80" @click="probando(1)"  color="#022D41" style="color:white" class="rounded-pill mt-3 mb-3 ">Profesional</v-btn>
                      </v-col>
                  </v-row>
              </v-card>
          </v-col>               
      </v-row>

      <v-row>
          <v-col cols="12" md="">
              <v-card>
                  <v-row>
                      <v-col>
                          <div class="title text-center mt-3 mb-3"><Planes/></div>
                      </v-col>
                  </v-row>
              </v-card>
          </v-col>               
      </v-row>

    
        

    <v-carousel class="d-flex d-sm-none" :show-arrows="false" hide-delimiters cycle>
          <v-carousel-item>
        <v-container class="d-flex d-sm-none">
          <v-row>
              <v-col  cols="6" sm="6">
                  <a  href="https://sosclick.cl/">
					<v-img
					width="100%"
					:src="require('../../../assets/imgs/test.png')"
					/>
					</a>
              </v-col>
              <v-col cols="6" sm="6">
                  <a href="https://www.iduam.com/">
					<v-img
                    style=""
                    width="100%"
					:src="require('../../../assets/imgs/test2.png')"
					/>
					</a>
              </v-col>
          </v-row>
        </v-container>  
          </v-carousel-item>
        <v-carousel-item>
            <v-row>    
            <v-col cols="6">
            <v-container>
                <v-img
                :class="!$vuetify.breakpoint.mobile ? '':'mt-8'"
                
				alt="SuPega Logo"
				contain
				:src="require('../../../assets/imgs/logo-big.svg')"
				transition="scale-transition"
				width="300"
			/>
            </v-container>
                </v-col>

                <v-col cols="6">
                <div class="mt-7" style="background:white">
		<v-row>
			<v-col cols="12" class="text-center ">
				<v-container style="color:#022D41">
				<div class="title">Hazte Premiun</div>
				<div class="subtitle-1">Cancelando 5.000$ al mes</div>
				<div class="subtitle-2">Puedes salir en todas las Búsquedas de captadores</div>
				</v-container>
			</v-col>
		</v-row>
		</div>
                </v-col>
            </v-row>
            
        </v-carousel-item>
      </v-carousel> 


  </v-container>
</template>

<script>
import Planes from '../Cliente/Planes'
import RegistroEmpleador from '../../Modals/Registro Empleador/RegistroEmpleador'
import Editar from '../Empleado/Editar'
import {logout } from '../../../Helpers/api/user'
export default {
    components:{Editar,RegistroEmpleador,Planes},
methods: {
	async logoutPanel(){
	await logout()
	this.$store.commit('setUserData', null)
	this.$router.push({name: 'Home'})
        },
        probando(status){
        this.$store.commit('setPreSelector', status)
        this.$router.push({name: 'Panel'})
    }
}

}
</script>

