<template>
<v-container fluid style="background:#eff3f2">
          <v-row>
              <v-col class="text-center" cols="12" sm="12">
                  <h1>Entrevistas</h1>
              </v-col>
          </v-row>

          <v-row justify="center">
              <v-col cols="12" sm="12">
                  <v-card>
                      <v-row>
                          <v-col cols="7" sm="8">
                            <div class="ml-5">
                              <div class="title">Video Llamada agendada</div>
                              <div class="subtitle-2">Fecha: Jueves 20 de Diciembre 2020</div>
                              <div class="subtitle-2">Hora: 2:00 pm</div>
                              <div class="subtitle-2">La reunión comienza en: 1 minuto</div>
                              <div class="subtitle-2">Empleador: Juan Rodriguez</div>
                              <div class="subtitle-2">Empresa: Rodriguez spa</div>
                            </div> 
                          </v-col>
                          <v-col class="text-center" cols="5" sm="4">
                            <v-row>
                                <v-col>
                                    <v-btn height="80" color="#D3E02F">
                                        <div class="d-flex flex-column">
                                        <v-icon style="font-size:4vh">mdi-video-outline</v-icon>
                                        <span>Ingresar</span>
                                        </div>
                                    </v-btn>        
                                </v-col>
                            </v-row>

                            <div class="mt-3">
                                <AvisoOferta/>
                             </div>

                            <v-row>
                                <v-col>
                                <v-btn color="#FD7B22">Cancelar</v-btn>
                                </v-col>
                            </v-row>
                          </v-col>
                      </v-row>
                  </v-card>

                  <v-card class="mt-5">
                      <v-row>
                          <v-col cols="7" sm="8">
                              <div class="ml-5">
                              <div class="title">Video Llamada agendada</div>
                              <div class="subtitle-2">Fecha: Jueves 20 de Diciembre 2020</div>
                              <div class="subtitle-2">Hora: 2:00 pm</div>
                              <div class="subtitle-2">La reunión comienza en: 25 horas</div>
                              <div class="subtitle-2">Empleador: Juan Rodriguez</div>
                              <div class="subtitle-2">Empresa: Rodriguez spa</div>
                              </div>
                          </v-col>
                          <v-col class="text-center" cols="5" sm="4">
                            <v-row>
                                <v-col>
                                    <v-btn height="80" color="#D3E02F">
                                        <div class="d-flex flex-column">
                                        <v-icon style="font-size:4vh">mdi-video-outline</v-icon>
                                        <span>Ingresar</span>
                                        </div>
                                        </v-btn>
                                    
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                <v-btn color="#FD7B22">Cancelar</v-btn>
                                </v-col>
                            </v-row>
                          </v-col>
                      </v-row>
                  </v-card>
              </v-col>
          </v-row>
      </v-container>
</template>

<script>
import AvisoOferta from './Botones avisos/AvisoOferta'
export default {
    components:{AvisoOferta},
    data:() =>({
        dialog:false,
    })

}
</script>
