<template>
  <v-container>
    <v-dialog class="rounded-xl" :width="!$vuetify.breakpoint.mobile ? '65%':'85%'"  v-model="dialog">
      <v-container style="background:white">
        <v-row justify="center">
          <v-col class="text-center" cols="12" sm="6">
            <h1>Nueva Oferta</h1>
          </v-col>

        </v-row>
          <v-row class="justify-center">
              <v-col cols="12" sm="6">
                  <v-select
                  background-color="#EFF3F2"
                  :items="items"
                  dense
                  label="Selecciona region"
                  solo
                  >
                  </v-select>
              </v-col>
          </v-row>

        <v-row class="justify-center">
              <v-col cols="12" sm="6">
                  <v-select
                  background-color="#EFF3F2"
                  :items="Comuna"
                  dense
                  label="Selecciona Comuna"
                  solo
                  >
                  </v-select>
              </v-col>
        </v-row>        

        <v-row class="justify-center">
              <v-col cols="12" sm="6">
                  <v-select
                  background-color="#EFF3F2"
                  :items="Profesion"
                  dense
                  label="Selecciona Profesion"
                  solo
                  >
                  </v-select>
              </v-col>
        </v-row>  
          <v-row class="justify-center">
              <v-col cols="12" sm="6">
            <v-textarea
            background-color="#EFF3F2"
            outlined
            maxlength="120"
            counter
            placeholder="Descripcion"
          ></v-textarea>
              </v-col>
          </v-row>

        <v-row>
          <v-col class="text-end">
          <v-btn @click="dialog = false" height="40px" style="background-color:#022D41; color:#D3E02F" class="mt-3" rounded>Guardar</v-btn>
          </v-col>  

          <v-col>
            <v-btn @click="dialog = false" width="110" height="40px" style="background-color:#022D41; color:#D3E02F" class="mt-3" rounded>Cerrar</v-btn>
          </v-col>
        </v-row>            

      </v-container>
    </v-dialog>
    <v-btn @click="dialog = true" height="40px" style="background-color:#022D41; color:#D3E02F"  rounded>Crear Ofertas</v-btn>
  </v-container>
</template>

<script>
export default {
data(){
  return{
    dialog:false,
    items:[
      'Metropolitana',
      'Concepcion',
      'Valparaiso'
    ],
    Comuna:[
      'Santiago',
      'Maipu',
      'San Miguel'

    ],
    Profesion:[
      'Informatico',
      'Diseñador',
      'Profesor'
    ]
    
  }
}
}
</script>

<style>

</style>