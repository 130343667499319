<template>
  <div>
    <ModalLogin />
    <ModalUserInfo />
  </div>
</template>

<script>
import ModalLogin from './ModalLogin'
import ModalUserInfo from './ModalUserInfo'

export default {
  name: 'ModalIndex',
  components: { ModalLogin, ModalUserInfo },
  data: () => ({
    
    //
  }),
};
</script>
