<template>
  <v-navigation-drawer
    v-model="drawer"
    fixed
    temporary
    @input="changeDrawer"
    class="px-5">
    <v-list-item>
      <v-list-item-content class="my-2">
        <v-img
          alt="SuPega Logo"
          class="shrink mr-2"
          contain
          :src="require('../../assets/imgs/logo-big.svg')"
          transition="scale-transition"
          max-width="220"
        />
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-btn
      class="my-2 text-left"
      text
      block
      color="#022D41"
      @click="$vuetify.goTo('#videocv')"><p class="mb-0 subtitle-1 font-weight-regular">VIDEO CV</p></v-btn>
    <v-btn
      class="my-2 text-left"
      text
      block
      color="#022D41"
      @click="$vuetify.goTo('#videoent')"><p class="mb-0 subtitle-1 font-weight-regular">VIDEO ENTREVISTA</p></v-btn>
    <v-btn
      class="my-2"
      rounded
      block
      color="#022D41"
      dark><p class="mb-0 body-2 font-weight-regular text-capitalize px-2 text-secondary">Ingresar</p></v-btn>
  </v-navigation-drawer>
</template>

<script>
  export default {
    name: 'Drawer',
    props: ['drawerOpen','changeDrawerStatus'],
    watch: {
      drawerOpen: {
        handler(data) {
          this.drawer = data
        },
        deep: true
      }
    },
    data: () => ({
      drawer: false,
      items: [
        { title: 'Dashboard', icon: 'mdi-view-dashboard' },
        { title: 'Photos', icon: 'mdi-image' },
        { title: 'About', icon: 'mdi-help-box' },
      ],
      right: null,
    }),
    methods: {
      changeDrawer(state){
        //to commit
        this.changeDrawerStatus(state)
      }
    }
  }
</script>