<template>
  <div id="SocketVideo">
    <v-dialog class="" v-model="dialog" width="1000">
        <v-container fluid style="background:white">
          <div id="textRoom" style="display: none;">
            <div id="textRoomContent">
              <h3>Text Room</h3>
            </div>
            <input id="textRoomInput" >
            <button onclick="textRoomPress();">Send</button>
          </div>
            <v-row class="mt-10">
              <v-col cols="6">
                <div class="text-end">
                  <div id="remoteViewContainer" style="min-height: 200px; min-width: 300px;"></div>
                </div>
              </v-col>
              <v-col cols="6">
                <div  id="localViewContainer" style="min-height: 200px; min-width: 300px; margin"></div>
              </v-col>
            </v-row>

            <v-row class="mt-5 mb-10">
              <v-col>
                <div  class="text-center">
                <v-btn @click="leave()" width="350" height="50" color="#FD2A22" style="color:white" rounded>Terminar Entrevista</v-btn>
                </div>
              </v-col>
            </v-row>
          <!-- <div id="roomIDContainer">
            <input id="roomID" value="abc">
            <button onclick="press();">Join/Create room</button>
          </div> -->
        </v-container>
    </v-dialog>  
    <div class="text-center">
      <v-btn @click="startVideollamada()" class="" height="45s" width="300" color="#022D41" style="background:#D3E02F" rounded >Atender</v-btn>
    </div>
  </div>
</template>

<script>
import io from 'socket.io-client'
export default {
  name: "SocketVideo",
  data() {
    return {
      dialog:null,
      socket: null,
      pcPeers: [],
      remoteStream: null,
      localStream: null,
      close:null,
    };
  },
  mounted() {
    
  },
  methods: {

    startVideollamada(){
      const self = this
      this.dialog = true
      this.socket = io('https://vctester.naturalphone.cl:16443/',
          {
            transports: ['websocket'],
            autoConnect: false,
            reconnect: true,
            forceNew: true
          })
      this.socket.on('connect', function() {
        // Esto se llama cuando te conectas al socket
        // Luego llamas al join para unirte a una sala
        // Esta sala es la que comentamos que iba a venir desde el backend para que en la app y en la web sea la misma
        // Ahora para efectos de prueba la sala es 123
        console.log("connect")
        self.join('123')
      });
      this.socket.on('exchange', function(data){
        self.exchange(data)
      });
      this.socket.on('leave', function(socketId){
        console.log("socketId", socketId)
      });

      // Conectamos al socket apenas se monta el componente, esto lo puedes manejar de otra forma o, si te sirve, así como está
      // También en esta función se pide el video del usuario si es que no lo ha pedido
      this.connectToSocket()
    },
    async connectToSocket(){
      if( !this.localStream ){
        this.localStream = await this.getUserMedia(true, true);
      }
      this.setVideo()
      this.socket.connect()
    },
    getUserMedia(video = true, audio = true){
      return new Promise(function(resolve, reject){
        let setTimeoutMedia = setTimeout(function(){
            reject();
        },40000)
        navigator.getUserMedia = navigator.getUserMedia || navigator.mozGetUserMedia || navigator.webkitGetUserMedia || navigator.msGetUserMedia;

        let videoOptions = false
        if(video){
          videoOptions = {
            width: { min: 426, ideal: 480, max: 1280 },
            height: { min: 240, ideal: 360, max: 720 },
            frameRate: { ideal: 10, max: 15 }
          }
        }

        navigator.getUserMedia ({
          video: videoOptions,
          audio
        },
          function(localMediaStream) {
            clearTimeout(setTimeoutMedia);
            resolve(localMediaStream);
        },
          function(err) {
            alert('El navegador no ha permitido el uso de la cámara y/o micrófono. O es posible que el computador no tenga una cámara conectada. Por favor revisa la configuración de tu navegador para luego continuar con el monitoreo.')
            console.log("Ocurrió el siguiente error: " + err);
        });
      });
    },
    setVideo(remote = false){
      if(remote){
        var ViewAuxRemote = document.getElementById("remoteViewContainer");
        var remoteVideo = document.createElement('video');
        remoteVideo.id = "remote-view";
        remoteVideo.srcObject = this.remoteStream;
        remoteVideo.autoplay = 'autoplay';
        ViewAuxRemote.appendChild( remoteVideo );
      }
      else{
        var ViewAuxLocal = document.getElementById("localViewContainer");
        var localVideo = document.createElement('video');
        localVideo.id = "local-view";
        localVideo.srcObject = this.localStream;
        localVideo.autoplay = 'autoplay';
        ViewAuxLocal.appendChild( localVideo );
      }
    },
    join(roomID){
      const self = this
      this.socket.emit('join', roomID, function(socketIds){
        for (var i in socketIds) {
          var socketID = socketIds[i];
          // Al hacer join te muestra los id de los usuarios que ya están conectados al socket
          // Luego se llama a la función que crea los peer connections
          // Nos aseguramos que no se llame a si mismo con el if
          if(socketID != self.socket.id){
            self.createPC(socketID, true);
          }
        }
      });
    },

    
    leave() {
      const self = this
      this.dialog = false
      return new Promise(function(){
        self.pcPeers.forEach(pc => {
          if (pc){
            pc.close();
          }
        });
        let video = document.getElementById("remote-view");
        video && video.remove();
        let videoPIP = document.getElementById("monitor-remote-pip-view");
        videoPIP && videoPIP.remove();
        if(close){
          self.localStream.getAudioTracks().forEach((track) => {
              track.enabled = false;
          });
          self.localStream.getVideoTracks().forEach((track) => {
              track.enabled = false;
          });
          self.localStream.getTracks().forEach(function(track) {
            track.stop();
          });
        }
        self.socket.disconnect()
        self.remoteStream = null
      })
    },

    exchange(data) {
        var fromId = data.from;
        var pc;
        const self = this

        if (fromId in  self.pcPeers) {
            pc = self.pcPeers[fromId];
        }
        else {
            pc = self.createPC(fromId, false);
        }

        if (data.sdp) {
          pc.setRemoteDescription(new RTCSessionDescription(data.sdp),() => {
              pc.createAnswer(function(desc) {
                  pc.setLocalDescription(desc,function () {
                      if(pc){
                        self.socket.emit('exchange', {'to': fromId, 'sdp': pc.localDescription });
                      }
                  },(error) => console.log(error,'error 4'));
              },(error) => console.log(error,'error 5'));
          },(error) => console.log(error,'error 6'));
        }
        else {
          data.candidate !== null && data.candidate && pc.addIceCandidate(new RTCIceCandidate(data.candidate));
        }
    },
    createPC(socketId, isOffer) {
      const self = this
      var configuration = {"iceServers": [{"url": "stun:stun.l.google.com:19302"}]};
      var pc = new RTCPeerConnection(configuration);
      self.pcPeers[socketId] = pc;

      pc.onicecandidate = function (event) {
          if (event.candidate && event !== null && event.candidate !== null) {
              self.socket.emit('exchange', {'to': socketId, 'candidate': event.candidate });
          }
      };

      function createOffer() {
          pc.createOffer(function(desc) {
              pc.setLocalDescription(desc,function () {
                  self.socket.emit('exchange', {'to': socketId, 'sdp': pc.localDescription });
              },(error) => console.log(error,'error 2'));
          },(error) => console.log(error,'error 3'));
      }

      pc.onnegotiationneeded = function () {
          if (isOffer) {
              createOffer();
          }
      }

      pc.oniceconnectionstatechange = function(event) {
          if (event.target.iceConnectionState === 'connected') {
              createDataChannel();
          } else if(event.target.iceConnectionState === 'checking'){
              createOffer();
          }
      };

      pc.onsignalingstatechange = function(event) {
          if(event.target.connectionState === 'disconnected'){
            self.leave();
          }
      };

      pc.onaddstream = function (event) {
          self.remoteStream = event.stream
          self.setVideo(true)
      };

      pc.addStream(self.localStream);

      function createDataChannel() {
          if (pc.textDataChannel) {
              return;
          }
          var dataChannel = pc.createDataChannel("text");
          dataChannel.onerror = function (error) {
            console.log(error)
          };
          dataChannel.onmessage = function (event) {
            console.log(event)
          };
          dataChannel.onopen = function () {
          };
          dataChannel.onclose = function () {
          };

          pc.textDataChannel = dataChannel;
      }

      return pc;
    }
  },
  
};
</script>

<style lang="scss" scoped>
</style>