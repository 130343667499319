<template>
	<v-navigation-drawer
		permanent
		color="#EFF3F2"
		:class="!$vuetify.breakpoint.mobile ? '':'d-none d-sm-flex'"
	>
		<v-list-item style="background-color:white" class="py-8 dflex justify-center">
			<v-img
				alt="SuPega Logo"
				contain
				:src="require('../../../assets/imgs/logo-big.svg')"
				transition="scale-transition"
				max-width="80"

			/>
		</v-list-item>

		<v-divider></v-divider>
		<v-text-field
			v-model="search"
			label="Búsqueda"
			color="#022D41"
			hide-details="auto"
			class="search-input ma-4"
		>
      <template v-slot:append>
				<v-btn color="#D3E02F" dark depressed>
					<v-icon color="#022D41">
						mdi-magnify
					</v-icon>
				</v-btn>
      </template>
		</v-text-field>
	<v-divider></v-divider>
		<div class="px-5 mt-5">
			<h3 class="text-primary">Áreas</h3>
			<v-list dense>
				<v-list-item
					dense
					v-for="item in items"
					:key="item.title"
					link
					style="min-height: 20px;"
				>
					<v-list-item-content class="py-1">
						<v-list-item-title>{{ item.title }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</div>

			<v-carousel :show-arrows="false" hide-delimiters cycle>
	<v-carousel-item>

	<v-row>
		<v-col class="text-center" cols="12">
			<v-container>
			<div class="title rounded-xl pt-3" style="background:#D3E02F;height:60px">¡Recomendación!</div>
			</v-container>
		</v-col>
	</v-row>
	<v-row>
		<v-col cols="12">
			<v-container>
				<div style="color:#022D41">Prepárate bien para tu entrevista es esencial para resaltar sobre los otros candidatos, tener en cuenta cuáles son tus puntos fuertes te ayudará a tener una mejor entrevista laboral.</div>
			</v-container>
		</v-col>
	</v-row>
	<v-row>
		<v-col cols="12">
			<v-img
			contain
			height="160"
			:src="require('../../../assets/imgs/Grupo-1279.svg')"
			></v-img>
		</v-col>
	</v-row>

	</v-carousel-item>

	<v-carousel-item>
	<v-row>
		<v-col class="text-center" cols="12">
			<v-container>
			<div class="title rounded-xl pt-3" style="background:#D3E02F;height:60px">¡Recomendación!</div>
			</v-container>
		</v-col>
	</v-row>	
	<v-row>
		<v-col cols="12">
			<v-container>
			<div style="color:#022D41">Crear una buena oferta Laboral te ayudará a conseguir mejores empleados.</div>
			</v-container>
		</v-col>
	</v-row>	
	<v-row>
		<v-col cols="12">
			<v-img
			contain
			height="160"
			:src="require('../../../assets/imgs/Grupo-1879.svg')"
			></v-img>
		</v-col>
	</v-row>
	</v-carousel-item>

	<v-carousel-item>
	<v-row>
		<v-col class="text-center" cols="12">
			<v-container>
			<div class="title rounded-xl pt-3" style="background:#D3E02F;height:60px">¡Recomendación!</div>
			</v-container>
		</v-col>
	</v-row>	
	<v-row>
		<v-col cols="12">
			<v-container>
			<div style="color:#022D41">Crear un buen perfil Laboral te ayudará a conseguir ese trabajo que siempre has querido. Al momento de Grabar tu video recuerda arreglarte un poco, las presencia personal es muy importante.</div>
			</v-container>
		</v-col>
	</v-row>	
	<v-row>
		<v-col cols="12">
			<v-img
			contain
			height="160"
			:src="require('../../../assets/imgs/Grupo-1879.svg')"
			></v-img>
		</v-col>
	</v-row>
	</v-carousel-item>

	</v-carousel>
	</v-navigation-drawer>
</template>

<script>

	export default {
		name: 'PanelDrawer',
		data () {
			return {
				search: '',
				users: [],
				items: [
					{ title: 'Agricultura' },
					{ title: 'Construcción' },
					{ title: 'Informática' },
					{ title: 'Transporte' },
					{ title: 'Cocina' },
					{ title: 'Ingeniería' },
				]
			}
		}
	}
</script>