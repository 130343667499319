<template>
  <v-container fluid style="background:#ededed;border:solid 0.5px;border-color:#d6d1cb;" class="pa-0 mt-12">
      <v-row>
          <v-col cols="2">
              <div class="d-flex flex-nowrap ml-5">
              <v-btn class="mt-2" icon><v-icon>mdi-emoticon-happy-outline</v-icon></v-btn>
              <v-btn class="mt-2" icon><v-icon>mdi-paperclip</v-icon></v-btn>
              </div>

          </v-col>

          <v-col cols="8">
            <v-text-field         
            v-on:keyup.13="sendMessage"
            style="height:39px;"
            filled
            rounded
            dense
            placeholder="Escribe un mensaje aquí"
            v-model="message"                        
            >
            <template slot="append">
              <v-btn
                rounded
                class=" mt-n2 pb-n1"
                height="40"
                width="100px" 
                style="background:#D3E02F;margin-right: -2.5vh;" 
                text
                :disabled="message === ''"
                @click="sendMessage">
                <v-icon  outlined color="#022D41">mdi-send</v-icon>
              </v-btn>
            </template>
            </v-text-field>
          </v-col>

      </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"
import firebase from 'firebase/app'
import 'firebase/database'
import moment from 'moment'

export default {
  name: 'FooterChat',
  data() {
    return {
      message: '',
      salaChatID: null
    }
  },
  computed: {
    ...mapGetters(["idSalaChat"])
  },
  watch:{
    idSalaChat: function(data){
      this.salaChatID = data
    }
  },
  methods: {

    sendMessage(){
      console.log("Message: ", this.message)
      if(this.message !== ''){
        firebase.database(this.$store.state.primaryDB).ref('Chat/' + this.salaChatID).push(
        {
          date: moment(new Date()).format('HH:mm'),
          message: this.message,
          id_usuario: this.$store.state.userData.id
        })
        this.message = ''
      }

      
    }

  }
}
</script>

<style>

</style>