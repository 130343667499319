<template>
  <v-container class="pa-0" style="width:100%" fluid>
      <Header/>
      <Body/>
      <Footer/>
      
  </v-container>
</template>

<script>
import Header from './Header'
import Body from './Body'
import Footer from './Footer'
export default {
    components:{Header,Body,Footer}
}
</script>

<style>

</style>