import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
const token = localStorage.getItem('userToken')

const store = new Vuex.Store({
	state: {
		isAuthenticated: token,
		userData: null,
		modalLogin: false,
		userInfo: null,
		showModalUserInfo: false,
		modalsStates:{
		editProfile: false,
		},
		PreSelector: localStorage.getItem('PreSelector') ? parseInt( localStorage.getItem('PreSelector') ) : 1,
		primaryDB: null,
		idSalaChat: null,
		MensajeAviso: null
	},
	mutations: {
		setUserData (state, user) {
			state.userData = user
			console.log("US:", user)
			state.isAuthenticated = localStorage.getItem('userToken')
		},
		logoutUser (state) {
			state.userData = null
			state.isAuthenticated = null
      localStorage.removeItem('userToken')
		},
		setModalLogin (state, status){
			state.modalLogin = status
		},
		setModalUserInfo (state, info){
			state.userInfo = info.data
			state.showModalUserInfo = info.status
		},
		setPreSelector (state, status){
			state.PreSelector = status
			localStorage.setItem('PreSelector', status )
		},
		setPrimaryDB (state,value) {
			state.primaryDB = value
		},
		setIdSalaChat (state,value) {
			state.idSalaChat = value
		},
		setMensajeAviso (state,value){
			state.MensajeAviso = value
		},
	},

	getters: {
		userData: state => {
			return state.userData
		},
		idSalaChat: state => {
			return state.idSalaChat
		},
	}
})
	

export default store
