<template>
	<div class="d-flex flex-nowrap" :style="!$vuetify.breakpoint.mobile ? 'width:86%; ':'width:100%'">
	<v-tabs
    centered
    color="#022D41"
    white
    icons-and-text
	
	>
	<v-tabs-slider style="display:none" color="white"></v-tabs-slider>

			<v-tab>
				<h5>Perfiles</h5>
				<v-icon dark x-large>mdi-account-check</v-icon>
			</v-tab>

			<v-tab>
				<h5>Crear Ofertas</h5>
				<v-icon dark x-large>mdi-file-document-outline</v-icon>
			</v-tab>
			<v-tab>
				<h5>Entrevistas</h5>
				<v-icon dark x-large>mdi-message-video</v-icon>
			</v-tab>

			<v-tab>
				<h5>Chat</h5>
			<v-icon dark x-large>mdi-message-outline</v-icon>
			</v-tab>

			<v-tab>
				<h5>Menu</h5>
				<v-icon x-large dark>mdi-menu</v-icon>
			</v-tab>

			<v-tab-item wi>
			<Perfiles/>
			</v-tab-item>

			<v-tab-item>
				<CrearOfertas/>
			</v-tab-item>

			<v-tab-item>
				<Entrevistas/>
			</v-tab-item>

			<v-tab-item>
				<Index/>
			</v-tab-item>
			
			<v-tab-item>
				<Menu/>
			</v-tab-item>
			


		</v-tabs>
	</div>  
</template>

<script>
		import Index from '../Chat/Index'
		import Menu from '../Cliente/Menu'
		import Entrevistas from '../Cliente/Entrevistas'
		import Perfiles from '../Cliente/Perfiles'
		import CrearOfertas from '../Cliente/CrearOfertas'
		import { getUsersLaboral, logout } from '../../../Helpers/api/user'
		

	export default {
		components:{Perfiles,CrearOfertas,Entrevistas,Menu,Index},
		name: 'UsersList',
		data () {
			return {
				tabs:null,
				users: []
			}
		},
		mounted(){
			this.getUsers()
		},
		methods: {
			async logoutPanel(){
				await logout()
				this.$store.commit('setUserData', null)
				this.$router.push({name: 'Home'})
			},
			getUsers(){
				const self = this
				getUsersLaboral()
					.then(function(response){
						self.users = response.users
					})
					.catch(function(error){
						console.log(error)
					})
			},
						editProfile(){
				this.$store.commit('setModalsStates', {editProfile: true})
				this.$store.commit('setDrawerState', false)
			},
			
		}
	}
</script>