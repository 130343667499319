<template>
  <div id="AvisoLlamada">
    <v-dialog v-model="dialog" width="800">
      <v-container style="background:white; height:600px">
        <v-row>
          <v-col>
            <div class="text-end">
            <v-btn @click="dialog = false" x-large color="black" icon><v-icon>mdi-close</v-icon></v-btn>
            </div>
          </v-col>
        </v-row>

        <v-row class="mt-10">
          <v-col>
            <div>
            <v-img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Coca-Cola_bottle_cap.svg/163px-Coca-Cola_bottle_cap.svg.png"
            height="125px"
            contain
            />
            </div>
          </v-col>
        </v-row>

        <v-row class="mt-5">
          <v-col>
            <div class="text-center">
            <p style="color:#022D41" class="display-1">Video Llamada Entrante...</p>
            </div>
          </v-col>
        </v-row>

        <v-row class="mt-8">
          <v-col>
            <div class="text-center">
              <SocketVideo/>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
          <v-btn @click="dialog = true" height="80" color="#D3E02F">
        <div class="d-flex flex-column">
          <v-icon style="font-size:4vh">mdi-video-outline</v-icon>
          <span>Ingresar</span>
        </div>
      </v-btn>
  </div>
</template>

<script>
 import SocketVideo from './SocketVideo'
 import firebase from 'firebase/app'
 import 'firebase/database'
export default {
  components: {SocketVideo},
  name: "AvisoLlamada",
  data() {
    return {
      dialog: false
    };
  },
  methods: {
      AvisoMensaje(){
        firebase.database(this.$store.state.primaryDB).ref('MensajeTest/').once("value", snapshot => {
           if (!snapshot.exists()){
              firebase.database(this.$store.state.primaryDB).ref('MensajeTest/').push({ date: new Date() })
           }
        })
        this.$store.commit('setMensajeAviso')
      },
      // async setMensajeAviso(){
      //   const Aviso = await setMensajeAviso()
      //   this.Aviso = Aviso.Aviso
      //   console.log(Aviso)
      // }

    },
}
</script>

<style lang="scss" scoped>
</style>