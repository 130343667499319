import axios from 'axios'
import store from '../Stores/stores.js'

const service = axios.create({
  baseURL: 'http://201.238.254.93:8000/',
  timeout: 10000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    const token = localStorage.getItem('userToken')
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    config.headers['Content-Type'] = 'application/json'
    config.headers['X-Requested-With'] = 'XMLHttpRequest'
    return config
  },
  error => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response
    // console.log(res.code)
    // if the custom code is not 20000, it is judged as an error.
    if (response.status !== 200) {
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (response.status === 403 || response.status === 50008 || response.status === 50012 || response.status === 50014) {
        // to re-login
        localStorage.removeItem('token')
        location.reload()
      }
      return Promise.reject(res || 'Error')
    } else {
      return res
    }
  },
  async error => {
    if (error.response && (error.response.status === 403 || error.response.status === 401)) {
      await localStorage.removeItem('token')
      if(!store.state.modalLogin){
        location.reload()
      }
    }
    return Promise.reject(error)
  }
)

export default service
