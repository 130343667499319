<template>
<v-container>
    <v-snackbar
      v-model="showMessage"
      vertical
      bottom
      center
      :color="messageColor"
    >
      <p class="mb-0">{{message}}</p>
    </v-snackbar>
		
    <v-form
	ref="form"
	v-model="valid"
	lazy-validation
	class="d-flex flex-column flex-grow-1"
	style="max-width: 400px;width: 100%;"
	>
	<v-text-field
		v-model="email"
		label="Correo"
		:rules="emailRules"
		color="#022D41"
		hide-details="auto"
		class="login-inputs mb-4"
	></v-text-field>

	<v-text-field
		v-model="userPassword"
		label="Contraseña"
		:rules="passwordRules"
		:type="showPass ? 'text' : 'password'"
		:append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
		color="#022D41"
		hide-details="auto"
		class="login-inputs"
		@click:append="showPass = !showPass"
	></v-text-field>

    <v-tab class="mt-5">
		<v-btn 
		:disabled="!valid"
		color="#D3E02F"
		dark
		block
		depressed
		@click="loginUser">
			<p class="mb-0 body-1 font-weight-bold text-capitalize text-primary">Ingresar</p>
		</v-btn>
	</v-tab>

	</v-form>
</v-container>
</template>

<script>
	import {login} from '../../Helpers/api/user.js'
	export default {
		name: 'ModalLogin',
		data: () => ({
			valid: true,
			showMessage: false,
			messageColor: 'error',
			message: '',
			showPass: false,
			email: '',
			emailRules: [
				v => !!v || 'Email es obligatorio',
				v => /.+@.+\..+/.test(v) || 'Email debe ser válido',
			],
			userPassword: '',
			passwordRules: [
				v => !!v || 'Contraseña es requerida'
			],
		}),
		methods: {
			validate () {
				this.$refs.form.validate()
			},
			loginUser () {
				if( this.$refs.form.validate() ){
					const self = this
					this.loadingLogin = true
					login(this.email, this.userPassword)
						.then(function () {
							self.$router.push({name: 'PreLogin'})
							self.$store.commit('setModalLogin', false)
							self.loadingLogin = false
						})
						.catch(function (error) {
						self.message = 'Lo sentimos, ha ocurrido un error inesperado. Por favor inténtalo nuevamente.'
						if(error.response && error.response.status == 401){
							self.message = 'Email o contraseña incorrectos. Por favor verifica los datos ingresados.'
						}
						self.messageColor = 'primary'
						self.showMessage = true
						console.log(error.response)
						})
				}
			}
		}
	}
</script>

