<template>

	<div class="d-flex flex-nowrap" style="width:100%">
	<v-tabs
    centered
    color="#022D41"
    white
    icons-and-text
	
	>
	<v-tabs-slider color="#D3E02F"></v-tabs-slider>

	<v-tab>
      <v-img
			alt=""
			:src="require('../../../assets/imgs/Grupo-3579.svg')"
			max-width="80"
            />
	</v-tab>

	<v-tab class="ml-5">
	<h5>Video CV</h5>
	<v-icon dark x-large>mdi-file-video-outline</v-icon>
	</v-tab>

	<v-tab class="ml-5">
        <h5>Entrevistas</h5>
        <v-icon dark x-large>mdi-message-video</v-icon>
	</v-tab>

	<v-tab class="ml-5">
		<h5>Chat</h5>
	<v-icon dark x-large>mdi-message-outline</v-icon>
	</v-tab>

	<v-tab class="ml-5">
        <h5>Menu</h5>
        <v-icon x-large dark>mdi-menu</v-icon>
	</v-tab>

    <v-tab-item>
	<Trabajos/>
	</v-tab-item>

	<v-tab-item>
	<PerfilLaboral/>
	</v-tab-item>

	<v-tab-item>
	<Entrevista/>
	</v-tab-item>

	<v-tab-item>
	<Index/>
	</v-tab-item>

	<v-tab-item>
	<Menu/>
	</v-tab-item>

</v-tabs>
</div>  
</template>

<script>
	import Index from '../Chat/Index'
	import Menu from './Menu'
	import Trabajos from './Trabajos'
	import Entrevista from './Entrevistas'
	import PerfilLaboral from '././PerfilLaboral'
    import { getUsersLaboral, logout } from '../../../Helpers/api/user'
    

	export default {
		components:{Trabajos,PerfilLaboral,Entrevista,Menu,Index},
		name: 'UsersList',
		data () {
			return {
				tabs:null,
				users: []
			}
		},
		mounted(){
			this.getUsers()
		},
		methods: {
			async logoutPanel(){
				await logout()
				this.$store.commit('setUserData', null)
				this.$router.push({name: 'Home'})
			},
			getUsers(){
				const self = this
				getUsersLaboral()
					.then(function(response){
						self.users = response.users
					})
					.catch(function(error){
						console.log(error)
					})
			},
						editProfile(){
				this.$store.commit('setModalsStates', {editProfile: true})
				this.$store.commit('setDrawerState', false)
			},
			
		}
	}
</script>