<template>

	<v-navigation-drawer
    width="40vh"
		permanent
		color="#EFF3F2"
		class="dflex flex-nowrap"
	>
    <v-divider></v-divider>
		<v-list-item  style="background-color:white;height:10vh;" class="py-8 dflex justify-center">
            <v-row>
                <v-col>
                    <p class="text-start title mt-4"><v-icon color="#022D41" class="mr-4" style="font-size:4vh">mdi-arrow-left</v-icon>Volver</p>
                </v-col>
            </v-row>
		</v-list-item>
		

        <v-row>
            <v-col class="text-start">
                <div class="mt-2 ml-5">
                <p class="title"><v-icon class="mr-3" style="font-size:4vh">mdi-forum-outline</v-icon>Mensaje</p>
                </div>
            </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-container  class="px-0 pt-0 text-center " style="height:73.5vh;background:#F7F7F7">
          <v-btn @click="changeSala(sala.id)"  width="100%" height="100" style="background:#FFFFFF" v-for="(sala,index) in salas" :key="index" :style="salaChatID === sala.id ? 'background-color:#c5c5c5':''">
              <v-row class="dflex flex-nowrap">
                <v-col class="pr-0" cols="4">
                  <div>
                    <img
                      class="mt-1"
                      :src="sala.avatar"
                      alt="Coca-cola"
                      width="45"
                    />
                  </div>
                </v-col>

                <v-col class="pl-0">
                  <div class="dflex flex-nowrap">
                      <div class="subtitle-2 mt-2">{{sala.nombre}}</div>
                      <div class="caption mb-0">{{sala.last_message}}</div>
                  </div>
                </v-col>
              </v-row>
          </v-btn>
        </v-container>

        

	</v-navigation-drawer>
</template>

<script>
  import { mapGetters } from "vuex"
  import {chat} from '../../../../Helpers/api/user'
  import firebase from 'firebase/app'
  import 'firebase/database'

	export default {
		name: 'Lateral',
		data () {
			return {
        salas: [],
        salaChatID: null,
      
        
			}
		},
    methods: {
      changeSala(salaID){
        firebase.database(this.$store.state.primaryDB).ref('Chat/' + salaID).once("value", snapshot => {
           if (!snapshot.exists()){
              firebase.database(this.$store.state.primaryDB).ref('Chat/' + salaID).push({ date: new Date() })
           }
        })
        this.$store.commit('setIdSalaChat', salaID)
      },
      async chat(){
        const salas = await chat()
        this.salas = salas.chat_rooms
        console.log(salas)
      }

    },
    computed:{
      ...mapGetters(["idSalaChat"])
    },
    watch:{
      idSalaChat: function(data){
        this.salaChatID = data
      }

    },
  
    mounted() {
      this.chat()
    },
	}
</script>

<style>
</style>

