<template>
  <div class="fill-height dflex" style="flex: 1">
		<v-container
      :class="!$vuetify.breakpoint.mobile ? 'dflex flex-nowrap align-start pt-0 px-0':'dflex flex-nowrap align-start pt-0 px-0'"
			fill-height
			style="background-color: #EFF3F2;"
			fluid>

    <PanelDrawer/>
    <Navegacion/>
  </v-container>
  </div>
</template>

<script>
import PanelDrawer from './PanelDrawer'
import Navegacion from '././Navegacion'
export default {
  components:{Navegacion,PanelDrawer},
   data () {
      return {
      }
    }

}
</script>

<style>

</style>