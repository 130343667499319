<template>
<div>
  <v-dialog v-model="dialog">
    <v-container style="background:#FFFF" class="px-0 pa-0" fluid>
      <v-row class="ma-0">
        <v-col cols="1">
          <div class="mt-4">
            <v-btn @click.stop="dialog = false" class="title" text><v-icon class="mr-2">mdi-arrow-left</v-icon> Volver</v-btn>
          </div>
        </v-col>

        <v-col style="padding-left:35%" cols="11">
          <div class=" ml-5 d-flex flex-row">
          <v-img
          alt="corporativo"
          contain
          :src="require('../../../assets/imgs/logoicon.svg')"
          max-width="50"
          />
          <p class="pt-3 ml-3 display-1">PLANES</p>    
          </div>
        </v-col>
      </v-row>

      <v-container fluid style="background:#EFF3F2">
        <v-row justify="center">
          <v-col cols="3">
            <div class="pa-4" style="background:#FFFFFF">
              <div class="mb-4" style="background:#FD7B22;">
                <v-img
                alt="corporativo"
                contain
                :src="require('../../../assets/imgs/Estandar.svg')"
                width="370"
                height="250"
              />
              </div>          
              <div class="text-start mx-auto" style="width:90%">
                <p class="display-1 pl-5">Estandar</p> 
                <hr class="mx-auto" style=";border: solid 0.5px #FD7B22;"/>

                <div class="body-1 mb-4 mt-4 pl-5">Ofertas Activas <span class="title mr-2" style="float:right">01</span></div>
                <hr class="mx-auto" style="border: solid 0.5px #FD7B22;"/>

                <p class="body-1 mt-4 pl-5">Oferta Patrocinadas <span class="title mr-4" style="float:right">0</span></p>
                <hr class="mx-auto" style="border: solid 0.5px #FD7B22;"/>

                <p class="body-1 mt-4 pl-5">Video Llamadas <span class="title mr-2" style="float:right">05</span></p>
                <hr class="mx-auto" style="border: solid 0.5px #FD7B22;"/>

                <p class="body-1 mt-4 pl-5">Llamadas Telefonicas <span class="title mr-2" style="float:right">05</span></p>
                <hr class="mx-auto" style="border: solid 0.5px #FD7B22;"/>

                <p class="headline mt-4 text-center">Gratis</p>
              </div>
            </div>
          </v-col>
          <v-col cols="3">
            <div class="pa-4" style="background:#FFFFFF">
              <div class="mb-4" style="background:#FADA31;">
                <v-img
                alt="corporativo"
                contain
                :src="require('../../../assets/imgs/pime.svg')"
                width="370"
                height="250"
              />
              </div>
              <div class="text-start mx-auto" style="width:90%">
                <p class="display-1 pl-5">Pime</p>
                <hr class="mx-auto" style="border: solid 0.5px #FADA31;"/>

                <p class="body-1 mt-4 pl-5">Ofertas Activas <span class="title mr-2" style="float:right">05</span></p>
                <hr class="mx-auto" style="border: solid 0.5px #FADA31;"/>

                <p class="body-1 mt-4 pl-5">Oferta Patrocinadas <span class="title mr-2" style="float:right">03</span></p>
                <hr class="mx-auto" style="border: solid 0.5px #FADA31;"/>

                <p class="body-1 mt-4 pl-5">Video Llamadas <span class="title mr-2" style="float:right">20</span></p>
                <hr class="mx-auto" style="border: solid 0.5px #FADA31;"/>

                <p class="body-1 mt-4 pl-5">Llamadas Telefonicas <span class="title mr-2" style="float:right">100</span></p>
                <hr class="mx-auto" style="border: solid 0.5px #FADA31;"/>

                <p class="headline mt-4 text-center">Pago mensual</p>
              </div>
            </div>
          </v-col>
          <v-col cols="3">
            <div class="pa-4" style="background:#FFFFFF">
              <div class="mb-4" style="background:#2CBBCB;">
                <v-img
                alt="corporativo"
                contain
                :src="require('../../../assets/imgs/Empresa.svg')"
                width="370"
                height="250"
              />
              </div>          
              <div class="text-start mx-auto" style="width:90%">
                <p class="display-1 pl-5">Empresa</p>
                <hr class="mx-auto" style="border: solid 0.5px #2CBBCB;"/>

                <p class="body-1 mt-4 pl-5">Ofertas Activas <span class="title mr-2" style="float:right">15</span></p>
                <hr class="mx-auto" style="border: solid 0.5px #2CBBCB;"/>

                <p class="body-1 mt-4 pl-5">Oferta Patrocinadas <span class="title mr-2" style="float:right">10</span></p>
                <hr class="mx-auto" style="border: solid 0.5px #2CBBCB;"/>

                <p class="body-1 mt-4 pl-5">Video Llamadas <span class="title mr-2" style="float:right">100</span></p>
                <hr class="mx-auto" style="border: solid 0.5px #2CBBCB;"/>

                <p class="body-1 mt-4 pl-5">Llamadas Telefonicas <span class="title mr-2" style="float:right"><v-icon color="black">mdi-infinity</v-icon></span></p>
                <hr class="mx-auto" style="border: solid 0.5px #2CBBCB;"/>

                <p class="headline mt-4 text-center">Pago mensual</p>
              </div>
            </div>
          </v-col>
          <v-col cols="3">
            <div class="pa-4" style="background:#FFFFFF">
              <div class="mb-4" style="background:#D3E02F">
                <v-img
                alt="corporativo"
                contain
                :src="require('../../../assets/imgs/corportacion.svg')"
                width="370"
                height="250"
              />
              </div>
              <div class="text-start mx-auto" style="width:90%">

                <p class="display-1 pl-5">Corporación</p>
                <hr class="mx-auto" style="border: solid 0.5px #D3E02F;"/>

                <p class="body-1 mt-4 pl-5">Ofertas Activas <span class="title mr-2" style="float:right">50</span></p>
                <hr class="mx-auto" style="border: solid 0.5px #D3E02F;"/>

                <p class="body-1 mt-4 pl-5">Oferta Patrocinadas <span class="title mr-2" style="float:right">25</span></p>
                <hr class="mx-auto" style="border: solid 0.5px #D3E02F;"/>

                <p class="body-1 mt-4 pl-5">Video Llamadas <span class="title mr-2" style="float:right"><v-icon color="black">mdi-infinity</v-icon></span></p>
                <hr class="mx-auto" style="border: solid 0.5px #D3E02F;"/>

                <p class="body-1 mt-4 pl-5">Llamadas Telefonicas <span class="title mr-2" style="float:right"><v-icon color="black">mdi-infinity</v-icon></span></p>
                <hr class="mx-auto" style="border: solid 0.5px #D3E02F;"/>

                <p class="headline mt-4 text-center">Pago mensual</p>
              </div>
            </div>   
          </v-col>        
        </v-row>
      </v-container>
    </v-container>
    </v-dialog>
  <div>
      <v-btn class="rounded-pill " width="290" height="80" style="background:#D3E02F" @click.stop="dialog = true"><v-icon class="pr-2" dark>mdi-text-box-multiple</v-icon> Planes</v-btn>
    </div>
</div>   
</template>
  

<script>
export default {
  props: {
  },
    name:'Planes',
data() {
    return {
    dialog: false,
    Planes:[
      {
        id: 1,
        name: 'Estandar',
        ofertaAct: 'Ofertas Activas',
        ofertaPatr: 'Oferta Patrocinadas',
        videoCall: 'Video Llamadas',
        llamadasT: 'Llamadas Telefonicas',
        monthly_value: 'Gratis',
      },
      {
        id: 2,
        name: 'Estandar',
        ofertaAct: 'Ofertas Activas',
        ofertaPatr: 'Oferta Patrocinadas',
        videoCall: 'Video Llamadas',
        llamadasT: 'Llamadas Telefonicas',
        monthly_value: 'Pago mensual 10.000$',
      },
      {
        id: 3,
        name: 'Estandar',
        ofertaAct: 'Ofertas Activas',
        ofertaPatr: 'Oferta Patrocinadas',
        videoCall: 'Video Llamadas',
        llamadasT: 'Llamadas Telefonicas',
        monthly_value: 'Pago mensual 50.000$',
      },
      {
        id: 4,
        name: 'Estandar',
        ofertaAct: 'Ofertas Activas',
        ofertaPatr: 'Oferta Patrocinadas',
        videoCall: 'Video Llamadas',
        llamadasT: 'Llamadas Telefonicas',
        monthly_value: 'Pago mensual 150.000$',
      }
    ]
    
    
    }
},
}
</script>

<style>

</style>