<template>
<v-container>
<v-form
    
    ref="form"
    v-model="valid"
    lazy-validation
  >
<v-text-field
  v-model="userInfo.email"
  :rules="isEmailValid"
  label="Email"
  @change="(email) => {isEmail(email)}"
  class="login-inputs"
></v-text-field>

<v-text-field
  v-model="userInfo.password"
  :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
  :rules="required"
  :type="showPass ? 'text' : 'password'"
  name="input-10-1"
  label="Contraseña"
  hint="Mínimo 8 caracteres"
  @click:append="showPass = !showPass"
  class="login-inputs"
></v-text-field>

<v-text-field
 v-model="userInfo.rut"
 :rules="rut"
 label="Rut"
 required
class="login-inputs"
></v-text-field>
  
<v-text-field
  v-model="userInfo.first_name"
  :rules="required"
  label="Nombre"
  class="login-inputs"
></v-text-field>

<v-text-field
  v-model="userInfo.last_name"
  :rules="required"
  label="Apellido"
  class="login-inputs"
></v-text-field>

 <v-text-field
   v-model="userInfo.address"
   :rules="required"
   label="Dirección"
   class="login-inputs"
 ></v-text-field>

  <v-text-field
  v-model="userInfo.phone_number"
  :rules="requiredPhone"
  label="Teléfono"
  :counter="11"
  maxlength="11"
  class="login-inputs"
></v-text-field>

  <v-select
   v-model="userInfo.genre_id"
   :items="items"
   label="Género"
   class="login-inputs"
 ></v-select>

<v-text-field
  v-model="userInfo.profession_id"
  label="Profesión u Oficio"
  class="login-inputs"
></v-text-field>

<v-text-field
  v-model="userInfo.business_name"
  label="Razón social"
  class="login-inputs"
></v-text-field>

<v-text-field
  v-model="userInfo.fantasy_name"
  label="Nombre Fantasia"
  class="login-inputs"
></v-text-field>

</v-form> 




<v-tab>
	<!-- <v-btn style="background:rgb(211, 224, 47);" color="#022D41" outlined dark block depressed :disabled="!valid" @click="validate">
		<p class="mb-0 body-1 font-weight-bold text-capitalize text-primary">Registrarse</p>
	</v-btn> -->
  <v-btn style="background:rgb(211, 224, 47);" class="mt-4" depressed dark block @click="() => registro()">Registrar</v-btn>
</v-tab>



</v-container>  
</template>

<script>
import { validate, format } from 'rut.js'
import {signup} from '../../Helpers/api/user.js'
export default {
    data:() => ({
      menu: false,
      showMessages: false,
      messages: null,
      showPass: false,
      registerOK: false,
      valid: false,
      // paises: [],
      // regiones: [],
      // comunas: [],

      userInfo:{
      rut:'',  
      sexo: 2,
      first_name :'',
      last_name  :'',
      email : '',
      address :'',
      phone_number :'',
      // fechaNamiciento:'',
      password : '',
      genre_id : '',
      business_name: '',
      fantasy_name: '',
      // comuna:null,
      },   
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],

    
    
	passwordRuless: [
	v => !!v || 'Contraseña es requerida'
    ],
          required: [
        v => !!v || 'Este campo es obligatorio'
      ],
          isEmailValid: [
        //eslint-disable-next-line
        v => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Formato incorrecto'
      ],
      requiredPhone: [
    v => !!v || 'Este campo es obligatorio',
    v => ( !!v && /^\d+$/.test(v) ) || 'Ingrese sólo números'
      ],
    items: [{text: 'Femenino', value: 2}, {text: 'Masculino', value: 1}],
    userInvite: null,
      rut: [
        v => (!!v || 'Este campo es obligatorio') && (validate(v) || 'Rut no válido')
      ],


     }),
    mounted(){
      // this.getPaises()
    },
      methods: {
        
      formatRut(rut) {
        this.userInfo.rut = format(rut)
      },
    validate() {
      this.$refs.form.validate()
    },
    
      // async getPaises(){
      //   const paises = await paisesActivos()
      //   this.paises = paises.paises
      //   console.log(paises)
      // },
      // async getRegion(pais_id){
      //   const regiones = await regionesActivas({pais_id})
      //   this.regiones = regiones.regiones
      // },
      // async getComunasActivas(region_id){
      //   const comunas = await comunasActivas({region_id})
      //   this.comunas = comunas.comunas
      // },
      //       async getUserWithCode(code){
      //   if(code){
      //     const data = { code }
      //     const user = await getUserByCode(data)
      //     this.userInvite = user.user
      //   }
      // },
        isEmail(email){
        //eslint-disable-next-line
        return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
      },
        async registro(){
        if(this.$refs.form.validate()){
          const self = this
          this.userInfo = this.$route.query
          const data = this.userInfo
          console.log(this.userInfo);
          
          signup(data)
            .then(async function (response) {
              self.registerOK = true
              console.log(response)
            })
            .catch(function (error) {
              self.messages = Object.values(error)
              self.showMessages = true
              console.log(self.messages)
            });
        }
      },
      
      
 },   

}
</script>

