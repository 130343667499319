import {endpoints} from './endpoints'
import request from '../request'
import store from '../../Stores/stores.js'

export function login (email, pass) {
	return new Promise((resolve, reject) => {
		const data = {
			email: email,
			password: pass
		}
		request({
			url: endpoints.login,
			method: 'post',
			data
		})
		.then(async function(response){
			setUserToken(response.data)
			await getUser()
			resolve(response.data)
		})
		.catch(function (error){
			console.log(error)
			reject(error)
		})

	})
}

export function logout () {
	return new Promise((resolve) => {			
		removeUserToken()
		setTimeout(function() {
			resolve(true)
		}, 500);
	})
}

function setUserToken (token) {
	localStorage.setItem('userToken', token.access_token)
}
function removeUserToken () {
	localStorage.removeItem('userToken')
}

export function getUser () {
	return new Promise((resolve) => {
		request({
			url: endpoints.user,
			method: 'get'
		})
			.then(function (response) {
				store.commit('setUserData', response.data.user_profile[0])
				resolve(response.data)
				console.log(response.data);
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function getUsersLaboral () {
	return new Promise((resolve) => {
		request({
			url: endpoints.getUsersInfo,
			method: 'POST'
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}
export function paisesActivos(){
	return new Promise((resolve) => {
		request({
			url: endpoints.paisesActivos,
			method: 'post'
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			});
	})
}

export function regionesActivas(data){
	return new Promise((resolve) => {
		request({
			url: endpoints.regionesActivas,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			});
	})
}

export function comunasActivas(data){
	return new Promise((resolve) => {
		request({
			url: endpoints.comunasActivas,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			});
	})
}
export function signUpByCode(data){
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.signUpByCode,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error.response.data)
			});
	})
}
export function getUserByCode(data){
	return new Promise((resolve) => {
		request({
			url: endpoints.getUserByCode,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			});
	})
}
export function updateUserAvatar(data){
	return new Promise((resolve) => {
		request({
			url: endpoints.updateUserAvatar,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			});
	})
}

export function updateUserInfo(data){
	return new Promise((resolve) => {
		request({
			url: endpoints.updateUserInfo,
			method: 'put',
			data
		})
		
		
			.then(function (response) {
				resolve(response)
				
			})
			.catch(function (error) {
				resolve(error)
			});
			
	})
}


export function chat(data) {
	return new Promise((resolve) => {
		request({
			url:endpoints.chat,
			method:'post',
			data
		})
		.then(function (response) {
			resolve(response)
		})
		.catch(function (error) {
			resolve(error)
		});
	})
}

export function signup(data){
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.signup,
			method: 'post',
			data
		})
		.then(function (response) {
			store.commit('setUserData', response.data.user_profile[0])
			resolve(response.data)
			console.log(response.data);
		})
			.catch(function (error) {
				reject(error.response.data)
			});
	})
}
	
