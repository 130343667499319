<template>
  <div>
      <v-dialog width="40%" v-model="dialog">
          <v-card-text class="rounded-lg" style="background: white;">
              <v-container>
                 <v-row>
                     <v-col cols="10">
                         <p class="display-1 text-center" style="margin-left:22%">Antes de comenzar agrega tu empresa</p>
                     </v-col>
                     <v-col cols="2">
                         <v-btn x-large class="ml-5" @click.stop="dialog = false" text><v-icon style="font-size:40px">mdi-close</v-icon></v-btn>
                     </v-col>
                 </v-row>

                 <v-row>
                     <v-col>
                        <div class="mx-auto square-background-round" @click="$refs.fileinput.click()">
                          <input
                            ref="fileinput"
                            type="file"
                            v-show="false"
                            :rules="rules"
                            @change="changeLogo"
                            accept="image/png, image/jpeg, image/bmp"
                            class="mx-auto"
                            />
                          <div class="d-flex flex-column fill-height align-center justify-center">
                            <v-icon color="#022D41" x-large>mdi-camera</v-icon>
                            <p class="mb-0" style="z-index: 9;color: #022D41;">Logo</p>
                          </div>
                        </div>
                      </v-col>
                 </v-row>
                 <v-form
                 ref="form"
                 >
                 <v-row>
                     <v-col cols="6">
                        <div class="mx-auto rounded-lg pt-3" style="border:solid 1px #707070;"> 
                            <v-text-field
                            v-model="razonS"
                            label="Razón social"
                            dense
                            class="login-inputs"
                            />
                        </div>
                     </v-col>

                     <v-col cols="6">
                        <div class="mx-auto rounded-lg pt-3" style="border:solid 1px #707070;"> 
                            <v-text-field
                            v-model="rutEmp"
                            label="Rut empresarial"
                            dense
                            class="login-inputs"
                            />
                        </div>    
                     </v-col>
                 </v-row>

                 <v-row>
                     <v-col cols="6">
                        <div class="mx-auto rounded-lg pt-3" style="border:solid 1px #707070;">
                            <v-select
                            v-model="pais"
                            label="País"
                            placeholder="País"
                            dense
                            class="login-inputs"
                            />
                        </div>                             
                     </v-col>

                     <v-col cols="6">
                         <div class="mx-auto rounded-lg pt-3" style="border:solid 1px #707070;">   
                            <v-select
                            v-model="region"
                            label="Region"
                            placeholder="Region"
                            dense
                            class="login-inputs"
                            />
                         </div>                             
                     </v-col>
                 </v-row>

                 <v-row>
                     <v-col cols="12">
                        <div class="mx-auto rounded-lg pt-3" style="border:solid 1px #707070;">     
                            <v-text-field
                            background-color="white"
                            v-model="direccion"
                            label="Direccion"
                            dense
                            class="login-inputs"
                            />
                        </div>    
                     </v-col>
                 </v-row>

                 <v-row>
                     <v-col cols="6">
                        <div class="mx-auto rounded-lg" style="border:solid 1px #707070;height:90%">
                            <v-text-field
                            background-color="white"
                            v-model="UrlEmp"
                            label="Sitio Web"
                            class="login-inputs"
                            />
                        </div>    
                     </v-col>

                     <v-col cols="6">
                        <v-btn class="mt-1" block height="50" @click.stop="dialog = false" rounded style="background:#022D41;color:#D3E02F">Agregar Empresa</v-btn>                             
                     </v-col>
                 </v-row>

                 </v-form>
              </v-container>
          </v-card-text>
      </v-dialog>
      <v-btn class="rounded-pill " width="200" height="80" style="background:#D3E02F" text @click="dialog = true"><v-icon>mdi-account-edit</v-icon>RegistroEmpleador</v-btn>
  </div>
</template>

<script>
export default {
name:'RegistroEmpleador',
data() {
    return {
        valid:true,
        dialog:false,
        nombre:'',
        rutEmp:'',
        EmailEmp:'',
        emailRules: [
        v => !!v || 'Correo requerido',
        v => /.+@.+\..+/.test(v) || 'El correo no es válido',
        ],
        pais:'',
        region:'',
        direccion:'',
        UrlEmp:'',
        rules:[
            value => !value || value.size < 2000000 || 'El tamaño del Logo debe ser menor que 2 MB!',
        ],
        fileLogo: null
    }
},
methods: {
  changeLogo(event){
    this.fileLogo = event.target.files[0]
  }
}
}
</script>

<style>

</style>