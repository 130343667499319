<template>
	<v-container fluid class="pa-0">
		<Landing />
		<VideoCV />
		<VideoEnt />
		<Outro />
	</v-container>
</template>

<script>
	import Landing from './Landing.vue'
	import VideoCV from './VideoCV.vue'
	import VideoEnt from './VideoEnt.vue'
	import Outro from './Outro.vue'
	export default {
		name: 'HomeIndex',
		components: { Landing, VideoCV, VideoEnt, Outro},
		data: () => ({
		}),
	}
</script>
