<template>
	<v-container
		fluid
		class="d-flex align-center"
		style="background-color: #022D41;">
		<v-container>
			<v-row class="d-flex align-center flex-column py-6">
				<v-col cols="12" class="py-0">
					<v-img
						alt="SuPega Background"
						class="shrink mr-2"
						contain
						:src="require('../../assets/imgs/logo-white.svg')"
						transition="scale-transition"
						max-height="100"
					/>
				</v-col>
				<v-col cols="12" class="d-flex flex-column my-2">
					<p class="mb-0 white--text text-center body-2">© 2013-2019 SUPEGA Todos los derechos reservados. Términos y Condiciones. Política de Privacidad y Protección de Datos Personales</p>
				</v-col>
			</v-row>
		</v-container>
	</v-container>
</template>

<script>
	export default {
		name: 'Footer',

		data: () => ({
		}),
	}
</script>
