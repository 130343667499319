<template>
	<v-container fill-height class="pa-0">
		<v-row class="fill-height pa-0 ma-0 dflex align-center justify-center">
			<v-col cols="12" class="pa-0 fill-height dflex align-center justify-center" style="position: relative;" v-if="!closedByUser">
				<div id="monitor" class="fill-height pa-0">
					<div id="monitor-remote" class="fill-height pa-0">
					</div>
				</div>
			</v-col>
			<v-col cols="12" class="pa-0 dflex align-center justify-center" v-else>
				<v-icon color="#022D41" x-large>
					mdi-account-remove-outline
				</v-icon>
				<h3 class="text-primary">Lo sentimos, se ha perdido la conexión con el usuario</h3>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import io from 'socket.io-client'
	import {startVideoCall} from '../../../Helpers/api/calls'

	export default {
		name: 'VideoCall',
		props: ['user'],
		data() {
			return {
				socketURL: 'https://vctester.naturalphone.cl:19443/',
				localStream: null,
				pcPeers: [],
				monitoreoActivo: false,
				socket: null,
				roomID: null,
				socketIdActivo: null,
				remoteStream: null,
				closedByUser: false
			}
		},
		mounted(){
			const self = this
			
			this.closedByUser = false
			this.localStream = null
			this.pcPeers = []
			this.roomID = null
			this.remoteStream = null

			this.socket = io(this.socketURL,
					{
						transports: ['websocket'],
						autoConnect: false,
						reconnect: true,
						forceNew: true
					})
			this.socket.on('connect', function() {
				console.log("connect")
				self.setMedia()
			})
			this.socket.on('exchange', function(data){
				self.exchange(data);
			})
			this.socket.on('leave', function(){
				self.closedByUser = true
				self.leave()
			})
			const time = + new Date()
			this.roomID = this.user.id.toString() + time.toString()
			this.startVideoLlamada(this.user.id, this.roomID)
		},
		beforeDestroy(){
			this.leave()
		},
		methods: {
			startVideoLlamada(user_id, callid){
				const data = { user_id, callid }
				this.socket.connect()
				startVideoCall(data)
			},
			async setMedia(){
				this.localStream = await this.getUserMedia(true,true)
				this.setLocalVideo()
				this.join(this.roomID)
			},
			getUserMedia(audio = true){
				return new Promise(function(resolve, reject){
					let setTimeoutMedia = setTimeout(function(){
							reject();
					},40000)
					navigator.getUserMedia = navigator.getUserMedia || navigator.mozGetUserMedia || navigator.webkitGetUserMedia || navigator.msGetUserMedia;

					navigator.getUserMedia ({
						video: {
							width: { min: 426, ideal: 480, max: 1280 },
							height: { min: 240, ideal: 360, max: 720 },
							frameRate: { ideal: 10, max: 15 }
						},
						audio
					},
						function(localMediaStream) {
							clearTimeout(setTimeoutMedia);
							resolve(localMediaStream);
					},
						function(err) {
							alert('El navegador no ha permitido el uso de la cámara y/o micrófono. O es posible que el computador no tenga una cámara conectada. Por favor revisa la configuración de tu navegador para luego continuar con el monitoreo.')
							console.log("Ocurrió el siguiente error: " + err);
					});
				});
			},
			join(roomID) {
				const self = this
				this.socket.emit('join', roomID, function(socketIds){
					console.log("emit join")
					for (var i in socketIds) {
						var socketID = socketIds[i];
						self.createPC(socketID, true)
					}
				});
			},
			leave() {
				const self = this
				return new Promise(function(resolve){
					self.pcPeers.forEach(pc => {
						if (pc){
							pc.close();
						}
					});
					let video = document.getElementById("remote-view");
					video && video.remove();
					self.localStream.getAudioTracks().forEach((track) => {
							track.enabled = false;
					});
					self.localStream.getVideoTracks().forEach((track) => {
							track.enabled = false;
					});
					self.localStream.getTracks().forEach(function(track) {
						track.stop();
					});
					self.socket.disconnect()

					resolve(true)
				})
			},
			exchange(data) {
					var fromId = data.from;
					var pc;
					const self = this

					if (fromId in  self.pcPeers) {
						pc = self.pcPeers[fromId];
					}
					else {
						pc = self.createPC(fromId, false);
					}

					if (data.sdp) {
						pc.setRemoteDescription(new RTCSessionDescription(data.sdp),() => {
							pc.createAnswer(function(desc) {
								pc.setLocalDescription(desc, function () {
									if(pc){
										self.socket.emit('exchange', {'to': fromId, 'sdp': pc.localDescription });
									}
								},(error) => console.log(error,'error 4'));
							},(error) => console.log(error,'error 5'));
						},(error) => console.log(error,'error 6'));
					}
					else {
						data.candidate !== null && data.candidate && pc.addIceCandidate(new RTCIceCandidate(data.candidate));
					}
			},
			createPC(socketId, isOffer) {
				const self = this
				var configuration = {"iceServers": [{"url": "stun:stun.l.google.com:19302"}]};
				var pc = new RTCPeerConnection(configuration);
				self.pcPeers[socketId] = pc;

				pc.onicecandidate = function (event) {
					if (event.candidate && event !== null && event.candidate !== null) {
						self.socket.emit('exchange', {'to': socketId, 'candidate': event.candidate });
					}
				};

				function createOffer() {
					pc.createOffer(function(desc) {
						pc.setLocalDescription(desc,function () {
							self.socket.emit('exchange', {'to': socketId, 'sdp': pc.localDescription });
						},(error) => console.log(error,'error 2'));
					},(error) => console.log(error,'error 3'));
				}

				pc.onnegotiationneeded = function () {
					if (isOffer) {
						createOffer();
					}
				}

				pc.oniceconnectionstatechange = function(event) {
					if (event.target.iceConnectionState === 'connected') {
						createDataChannel();
					} else if(event.target.iceConnectionState === 'checking'){
						createOffer();
					}
				};

				pc.onsignalingstatechange = function(event) {
					if(event.target.connectionState === 'disconnected'){
						self.leave();
					}
				};

				pc.onaddstream = function (event) {
					self.remoteStream = event.stream
					self.setLocalVideo(true)
				};

				pc.addStream(self.localStream);

				function createDataChannel() {
						if (pc.textDataChannel) {
								return;
						}
						var dataChannel = pc.createDataChannel("text");
						dataChannel.onerror = function (error) {
							console.log(error)
						};
						dataChannel.onmessage = function (event) {
							console.log(event)
						};
						dataChannel.onopen = function () {
						};
						dataChannel.onclose = function () {
						};

						pc.textDataChannel = dataChannel;
				}

				return pc;
			},
			setLocalVideo(remote = false){
				if(remote){
					var ViewAuxRemote = document.getElementById("monitor-remote");
					var selfViewRemote = document.createElement('video');
					selfViewRemote.id = "remote-view";
					selfViewRemote.srcObject = this.remoteStream;
					selfViewRemote.autoplay = 'autoplay';
					selfViewRemote.onloadedmetadata = function(e){
						console.log("selfViewRemote: ", e)	
						self.conectado = true;
					};          
					ViewAuxRemote.appendChild(selfViewRemote)
				}
				else{
					var ViewAux = document.getElementById("monitor");
					var selfViewLocal = document.createElement('video');
					selfViewLocal.id = "local-view";
					selfViewLocal.srcObject = this.localStream;
					selfViewLocal.autoplay = 'autoplay';
					ViewAux.appendChild(selfViewLocal);
					selfViewLocal.muted = "muted"
				}
			},
		}
	}
</script>
