import {endpoints} from './endpoints'
import request from '../request'

export function updateVideoLaboral(formData){
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateVideoLaboral,
			method: 'post',
			data: formData,
			timeout: 600000
		})
			.then(async function (response) {
				console.log(updateVideoLaboral)
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			});
	})
}