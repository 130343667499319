<template>
  <v-container>
      <v-dialog :width="!$vuetify.breakpoint.mobile ? '30%':'90%'" v-model="dialog">
        <v-container style="background:white">
        <v-row>
          <v-col>
            <p class="title text-center" style="color:#022D41">¡Tiene una nueva solicitud de entrevista!</p>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div class="mx-auto">
            <v-img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Coca-Cola_bottle_cap.svg/163px-Coca-Cola_bottle_cap.svg.png"
            height="125px"
            contain
            ></v-img>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="text-center" cols="12">
            <div style="color:#022D41">
            <p class="title">Arquitectos y Asociados</p>
            <p class="subtitle-1">Descripción de la empresa</p>
            <p class="subtitle-1">www.arquitectosyasociados.com</p>
            <p class="subtitle-1">Comuna: Las Condes, RM</p>
            <br>
            <p class="title">Para el día:</p>
            <p class="subtitle-1">Martes 25 de Enero del 2021</p>
            <p class="title">Hora:</p>
            <p class="subtitle-1">2:00pm</p>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="text-end" cols="6">
            <v-btn @click="dialog = false" width="150" height="40px" style="background-color:#D3E02F; color:#022D41" class="mt-3 subtitle-2" rounded>Aceptar</v-btn>
          </v-col>
          
          <v-col  cols="6">
            <v-btn @click="dialog = false" width="150" height="40px" style="background-color:white; color:#022D41" class="mt-3 subtitle-2" outlined rounded>Rechazar</v-btn>
          </v-col>
        </v-row>
        </v-container>      
        </v-dialog>  
        <div>
          <v-btn  @click="dialog=true">
            <div class="subtitle-2">PruebaOferta</div>
          </v-btn>
        </div>
  </v-container>
</template>

<script>
export default {
data(){
    return{
        dialog:false
    }
}
}
</script>

<style>

</style>