<template>
	<v-main class="Probando"> 
		<Header v-if="showHeader()"/>
		<router-view></router-view>
		<Footer v-if="showFooter()"/>
	</v-main>
</template>

<script>
import Header from './Header'
import Footer from './Footer'

export default {
	name: 'App',
	components: {
		Header,Footer
	},
	data: () => ({
		//
	}),
	methods: {
		showHeader(){
			return this.$router.currentRoute.name !== 'NuevoLogin' && this.$router.currentRoute.name !== 'Panel' && this.$router.currentRoute.name !== 'PreLogin' && this.$router.currentRoute.name !== 'NuevoRegistro' 
		},
		showFooter(){
			return this.$router.currentRoute.name !== 'NuevoLogin' && this.$router.currentRoute.name !== 'Panel' && this.$router.currentRoute.name !== 'PreLogin' && this.$router.currentRoute.name !== 'NuevoRegistro' 
		},
	}
};
</script>

<style>
.Probando{
    background-image: url('../../assets/imgs/ImgFondoAbajo.svg');
    background-position: center bottom;
    background-size: contain;
}
</style>