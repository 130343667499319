<template>
<div  class="mt-16 ">
  <v-container fluid >
      <v-row >
          <v-col cols="12">
            <v-img
            class="mx-auto"
            :style="!$vuetify.breakpoint.mobile ? '':'width:60%'"

            width="20%"
			:src="require('../../../assets/imgs/ImgSupegaLogin.svg')"
			/>
          </v-col>
      </v-row>
      <v-row>
          <v-col class="text-center" cols="12">
              <p style="color:#022D41" class="display-1">Ingresar como</p>
          </v-col>
      </v-row>
      <v-row>
          <v-col class="text-end" cols="6">
              <v-btn width="250" height="60" @click="probando(1)"  color="#022D41" :style="!$vuetify.breakpoint.mobile ? 'color:white':'color:white;height:8vh;width:70%'" large rounded>Profesional</v-btn>
          </v-col>

          <v-col cols="6">
              <v-btn width="250" height="60" @click="probando(9)" color="#D3E02F" :style="!$vuetify.breakpoint.mobile ? '':'height:8vh;width:70%'" large rounded>Empleador</v-btn>
          </v-col>
      </v-row>
      <Index/>
    </v-container>
</div>    
</template>

<script>
import index from '../../Panel/index'
export default {
components:{index},
methods: {
    probando(status){
        this.$store.commit('setPreSelector', status)
        this.$router.push({name: 'Panel'})
    }
}

}
</script>
    
<style>

</style>