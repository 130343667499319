<template>
	<v-container
		fluid
		class="d-flex align-center pa-0"
		style="background-color: #EFF3F2;">
		<v-container class="py-0">
			<v-row class="d-flex align-center flex-column py-0 mb-16">
				<v-col cols="12" class="py-0">
					<v-img
						alt="SuPega Background"
						class="shrink mr-2"
						contain
						:src="require('../../assets/imgs/back4.svg')"
						transition="scale-transition"
					/>
				</v-col>
				<v-col cols="12" class="d-flex flex-column px-4">
					<p class="mb-0 text-primary text-center headline font-weight-bold">SUPEGA es la manera mas fácil de conseguir el colaborador que estás buscando</p>
					<p class="my-3 text-primary text-center title font-weight-regular">El portal que permite conseguir al colaborador que estás buscando a través del sistema más moderno.</p>
				</v-col>
			</v-row>
		</v-container>
	</v-container>
</template>

<script>
	export default {
		name: 'HomeOutro',

		data: () => ({
		}),
	}
</script>
