const endpoints = {
	login: 'api/auth/login',
	user: '/api/auth/profile',
    updateUserInfo: '/api/auth/profile',
	signup: '/api/auth/signup',
	logout: '/api/auth/logout',

	// userDetails: '/api/auth/profile',
//   getUsersInfo: 'app/supega/getUsersInfo',
//   startVideoCall: 'app/supega/startVideoCall',
//    newToken: 'api/auth/device-token',
//   sendSMS: 'admin/calls/sendSMS',
// 	 getUserByCode: '/auth/getUserByCode',
// 	 signUpByCode: '/auth/signUpByCode',
// 	 comunasActivas: '/auth/getComunasActivas',
// 	 regionesActivas: '/auth/getRegionesActivas',
//   paisesActivos: '/auth/getPaisesActivos',
    //  updateVideoLaboral: '/api/workers/video',
  
	
// 	checkToken: '/auth/find',
// 	resetPass: '/auth/resetPass',
// 	updateUserAvatar: '/app/user/updateAvatar',

// 	createFirebaseAuthToken: 'app/user/createFirebaseAuthToken',
// 	lostMyPhoneData: 'app/lostphone/getLostMyPhoneData',
// 	startLostMyPhone: 'app/lostphone/lostMyPhoneSubscribeNotification',
// 	stopLostMyPhone: 'app/lostphone/lostMyPhoneUnSubscribeNotification',
// 	lockMyPhone: 'app/lostphone/blockMyPhoneNotification',
// 	wipePhoneData: 'app/lostphone/wipePhoneDataNotification',
// 	changeLostMyPhoneStatus: 'app/lostphone/changeStatus',
// 	getLostMyPhoneInfo: 'app/lostphone/getLostMyPhoneInfo',
// 	userMediciones: '/app/mediciones/getUserMediciones',
// 	validatePass: '/app/user/validatePass',
// 	getComunasActivas: '/auth/getComunasActivas',
// 	getAlertTempUserData: '/tempalert/getAlertTempUserData',
// 	getAtenciones: 'app/atenciones/getUserAtenciones',
// 	getAtencionDetail: 'app/atenciones/getAtencionDetail',
// 	getPlanes: '/app/planes/getPlanes',
// 	getPaymentValue: '/app/planes/getToPayData',
// 	startPaymentPlan: '/app/pagos/newPlanPayment',
// 	getOrderStatusByToken: '/auth/getOrderStatusByToken',
// 	getSecurityNet: '/app/grupos/misInvitados',
// 	getSecurityExternal: '/app/grupos/misGrupos',
// 	getTrackingByDevice: '/app/tracking/getTrackingByDevice',
// 	activateTracking: 'admin/tracking/toogleTrackingNotification',
// chat: 'app/supega/testingSuPega',

}
export {endpoints}
