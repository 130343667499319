<template>
	<v-dialog
		v-model="this.$store.state.showModalUserInfo"
		persistent
		fullscreen
		content-class="modal-login dflex justify-center align-center text-center"
	>
		<v-container class="pa-16 dflex justify-center align-center fill-height">
			<div class="modal-user-info dflex justify-center align-center">
				<v-btn
					icon
					color="#022D41"
					style="position: absolute; right: 3%; top: 3%;"
					@click="$store.commit('setModalUserInfo', { status: false, data: null })"
				>
					<v-icon style="font-size: 30px;">mdi-close</v-icon>
				</v-btn>
				<v-row v-if="this.$store.state.userInfo" class="fill-height ma-">
					<v-col cols="12" md="6" class="pa-0" style="position: relative;" v-if="!videocall">
						<video-player
						class="video-player-box vjs-big-play-centered vjs-big-play-button "
							ref="videoPlayer"
							:options="{
								
								language: 'es',
								sources: [{
									type: 'video/mp4',
									src: 'https://zonecam.naturalphone.cl/apiSOSclick/storage/app/public/' + this.$store.state.userInfo.laboral.video
								}]
							}"
							:playsinline="true">
						</video-player>
						<div class="d-flex flex-column" style="position: absolute;top: 30%;z-index: 9;right: -10px;">
							<v-tooltip  right>
								<template v-slot:activator="{ on, attrs }">
									<v-btn small dark depressed class="buttons-video-modal" style="color:black; border:solid 1px; background-color:white" v-bind="attrs" v-on="on"
										:class="{'disable-events': loadingCall || deviceStatus === 1}"
										>
										<v-icon color="#022D41" large>
											mdi-email-outline
										</v-icon>
									</v-btn>
								</template>
								<span>Enviar Mensaje</span>
							</v-tooltip>

							<v-tooltip right>
								<template v-slot:activator="{ on, attrs }">
									<v-btn small dark depressed class="buttons-video-modal" style="color:black;border:solid 1px; background-color:white" v-bind="attrs" v-on="on"
										:class="{'disable-events': loadingCall || deviceStatus === 1}"
										>
										<v-icon color="#022D41" large>
											mdi-whatsapp
										</v-icon>
									</v-btn>
								</template>
								<span>Enviar Mensaje</span>
							</v-tooltip>						

							<v-tooltip right>
								<template v-slot:activator="{ on, attrs }">
									<v-btn small dark depressed class="buttons-video-modal" style="color:black; border:solid 1px; background-color:white" v-bind="attrs" v-on="on"
										:loading="loadingCall"
										:class="{'disable-events': loadingCall || deviceStatus === 1}"
										@click="call($store.state.userInfo.info.telefono)">
										<v-icon color="#022D41" large>
											mdi-phone
										</v-icon>
									</v-btn>
								</template>
								<span>Iniciar Llamada Teléfonica</span>
							</v-tooltip> 

							<v-tooltip right>
								<template v-slot:activator="{ on, attrs }">
									<v-btn small dark depressed class="buttons-video-modal" style="color:black; border:solid 1px; background-color:white" v-bind="attrs" v-on="on"
										:class="{'disable-events': loadingCall || deviceStatus === 1}"
										>
										<v-icon color="#022D41" large>
											mdi-video-account
										</v-icon>
									</v-btn>
								</template>
								<span>Iniciar Videollamada</span>
							</v-tooltip>
						</div>

<!-- 						<v-tooltip right>
							<template v-slot:activator="{ on, attrs }">
								<v-btn small color="#D3E02F" dark depressed class="buttons-video-modal" style="top: 115px;" v-bind="attrs" v-on="on"
									:loading="loadingCall"
									:class="{'disable-events': loadingCall || deviceStatus === 1}"
									@click="call($store.state.userInfo.info.telefono)">
									<v-icon color="#022D41" large>
										mdi-cellphone-message
									</v-icon>
								</v-btn>
							</template>
							<span>Enviar SMS</span>
						</v-tooltip> -->
					</v-col>
					<v-col cols="12" md="6" class="pa-0 ma-0" v-else>
						<VideoCall :user="$store.state.userInfo" />
					</v-col>
					<v-col cols="12" md="6" class="text-center py-8 px-12 mt-16 dflex align-center justify-center">
						<div class="d-flex flex-column justify-space-between fill-height">
							<div>
								<v-img
									alt="SuPega Logo"
									class="ma-auto avatar-rombo"
									:src="$store.state.userInfo.info.avatar"
									transition="scale-transition"
									:height="90"
									:width="90"
									:aspect-ratio="1"
								/>
								<div  class="text-center mx-auto ml-2">	
									<p class="text-primary title mt-4 mb-1">{{$store.state.userInfo.name}}</p>
									<p class="text-primary title mb-2 font-weight-regular">{{$store.state.userInfo.laboral.area[0]}}</p>
								</div>
								<div class="text-start mx-10">
									<p class="text-primary mb-n2"><b  class="subtitle-1 font-weight-bold">Edad: </b> {{$store.state.userInfo.info.nacimiento | age}}</p>
									<p class="text-primary mb-n2"><b class="subtitle-1 font-weight-bold">Comuna: </b> {{$store.state.userInfo.info.comuna_data.comuna}}</p>
									<p class="text-primary mb-n2"><b class="subtitle-1 font-weight-bold">Dirección: </b> {{$store.state.userInfo.info.direccion}}</p>
									<p class="text-primary subtitle-1 mt-5">{{$store.state.userInfo.laboral.descripcion}}</p>
								</div>
							</div>

						<!-- Boton Entrevista -->
						<div>
							<v-row>
								<v-col>
									<v-btn @click="dialogEntrevista = true" block color="#022D41" style="color:#D3E02F;" rounded>Agendar Entrevista</v-btn>
									<Entrevista :openDialog="dialogEntrevista" :closeDialog="() => dialogEntrevista = false"/>
								</v-col>
								<v-col>
									<v-btn @click="dialog1 = true" block color="#022D41" style="color:#022D41" outlined rounded>Enviar mensaje</v-btn>
								</v-col>
							</v-row>
						</div>
					</div>

						<!-- Boton Entrevista -->
						
						<!-- <v-row class="text-center my-5" v-if="deviceStatus == 9">


							<v-col cols="12" md="6" class="d-flex flex-column align-center justify-center">
								<v-btn small color="#D3E02F" dark block depressed class="py-4" style="border-radius: 10px;"
									:class="{'disable-events': loadingCall || deviceStatus === 1}"
									@click="startVideoLlamada()">
									<p class="mb-0 font-weight-bold text-capitalize text-primary">Videollamada</p>
								</v-btn>
							</v-col>
							<v-col cols="12" md="6" class="d-flex flex-column align-center justify-center">
								<v-btn small color="#022D41" outlined dark block depressed class="py-4" style="border-radius: 10px;"
									:class="{'disable-events': loadingCall || deviceStatus === 1}"
									:loading="loadingCall"
									@click="call($store.state.userInfo.info.telefono)">
									<p class="mb-0 font-weight-bold text-capitalize text-primary">Llamar</p>
								</v-btn>
							</v-col>

						</v-row> -->
						<v-row class="text-center my-5" v-if="deviceStatus === 1">
							<v-col cols="12" md="12" class="d-flex flex-column align-center justify-center">
							<p>Llamada en curso</p>
							<v-btn small color="#D3E02F" dark block depressed class="py-4" style="border-radius: 10px;" @click="endCall()">
								<p class="mb-0 font-weight-bold text-capitalize text-primary">Terminar Llamada</p>
							</v-btn>
							</v-col>
						</v-row>
						<v-row class="text-center my-5" v-if="deviceStatus === 2">
							<v-col cols="12" md="12" class="d-flex flex-column align-center justify-center">
							<p>Videollamada en curso</p>
							<v-btn small color="#D3E02F" dark block depressed class="py-4" style="border-radius: 10px;" @click="endVideoLlamada">
								<p class="mb-0 font-weight-bold text-capitalize text-primary">Terminar Videollamada</p>
							</v-btn>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</div>
		</v-container>
	</v-dialog>
</template>

<script>
	import Entrevista from './Entrevista/Entrevista'
	import moment from 'moment'
	import 'video.js/dist/video-js.css'
	import { videoPlayer } from 'vue-video-player'
	import {Device} from 'twilio-client'
	import {newToken} from '../../Helpers/api/calls'
	import VideoCall from './VideoCall/VideoCall'

	export default {
		name: 'ModalUserInfo',
		components: {videoPlayer, VideoCall,Entrevista},
		filters: {
			age: function (value) {
				if (!value) return ''
				return moment().diff(value, 'years')
			}
		},
		data() {
			return {
				loadingCall: false,
				deviceStatus: 9,
				videocall: false,
				dialog1: false,
				dialogEntrevista: false
			}
		},
		methods: {
			startVideoLlamada(){
				this.videocall = !this.videocall
				this.deviceStatus = 2
			},
			endVideoLlamada(){
				this.videocall = false
				this.deviceStatus = 9			
			},
			call(phoneToCall){
				const self = this
				this.loadingCall = true
				Device.ready(function (device) {
					self.loadingCall = false
					device.connect({"phoneNumber": '+' + phoneToCall})
				})
				Device.on('connect',function () {
					self.deviceStatus = 1
				})
				Device.on('disconnect',function () {
					self.deviceStatus = 9
					Device.destroy()
				})
				newToken()
					.then(function(resp){
						Device.setup(resp.token)
					})
					.catch(function(error){
						self.loadingCall = false
						console.log(error)
					})
			},
			endCall(){
				this.loadingCall = false
				Device.destroy()
			}
		}
	}
</script>

<style >
.vjs-big-play-centered .vjs-big-play-button {
	background-image: url('../../assets/imgs/play.svg');
	background-repeat: no-repeat;
	background-size: 100%;
	background-color: transparent !important;
	height: 50%;
	margin-top: -50px;
	border: none !important;
	box-shadow: none !important;
}
</style>
