<template>
	<v-container
		id="videoent"
		fluid
		class="d-flex align-center pa-0 container-block-background videoent"
		style="background-color: #D3E02F;">
		<v-container class="d-flex align-center">
			<v-row>
				<v-col cols="12" md="7" >
				</v-col>
				<v-col cols="12" md="5" class="d-flex flex-column">
					<p class="mb-0 text-primary display-1 font-weight-bold">VIDEO ENTREVISTA</p>
					<p class="my-8 text-primary headline" style="font-size: 1.2rem;line-height: 1.5rem;">A través de nuestro moderno sistema puedes entrevistar a tu futuro colaborador de manera rápida y sencilla por medio de una video llamada.</p>
					<v-btn block x-large rounded color="#022D41" dark class="px-16" @click="toLogin()">
						<p class="mb-0 title font-weight-regular text-capitalize text-secondary">Ingresar</p>
					</v-btn>
				</v-col>
			</v-row>
		</v-container>
	</v-container>
</template>

<script>
	export default {
		name: 'VideoEnt',
		data: () => ({
		}),
		methods: {
			toLogin(){
				if(this.$store.state.isAuthenticated){
					this.$router.push({name: 'PreLogin'})
				}
				else{
					this.$store.commit('setModalLogin', true)
				}
			}
		}
	}
</script>
