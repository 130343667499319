<template>
	<v-container fluid fill-height class="pa-0 dflex flex-nowrap">
		
		<!-- <PanelDrawer /> -->
		<Cliente  v-if="$store.state.PreSelector === 9"/>
		<Empleado v-if="$store.state.PreSelector === 1"/>
		<Anuncios />
		
	</v-container>
</template>

<script>
	import Anuncios from './Anuncios'
	import Cliente from '././Cliente/Cliente.vue'
	import Empleado from '././Empleado/Empleado.vue'
	// import PanelDrawer from './PanelDrawer'
	

	
	export default {
	name: 'PanelIndex',
	// PanelDrawer
    components: {  Empleado, Cliente,Anuncios},

		data () {
			return {
				drawer: true,
				items: [
					{ title: 'Home', icon: 'mdi-home-city' },
					{ title: 'My Account', icon: 'mdi-account' },
					{ title: 'Users', icon: 'mdi-account-group-outline' },
				],
				mini: true,
			}
		},
	}
</script>