<template>
<v-container :class="!$vuetify.breakpoint.mobile ? '':'mr-3 pr-16'" fluid style="background:#eff3f2;">
          <v-row justify="center">
              <v-col cols="12" sm="12">
                  <v-card class="mt-5">
                      <v-row>
                          <v-col cols="8" sm="8">
                            <div style="color:#022D41" class="ml-10 mt-2">
                              <div class="title mb-2">Arquitectos y Asociados</div>
                              <div class="subtitle-2 mb-2">Se busca carpintero con experiencia</div>
                              <div class="subtitle-2 mb-2">Comuna: Las Condes, RM</div>
                              <div class="subtitle-2">Fecha: 20/12/20</div>
                            </div> 
                          </v-col>
                          <v-col cols="4" sm="4">
                              <v-row >
                                  <v-col>
                                    <div class="mr-5 mt-2 text-end">
                                      <EnviarPostulacion/>
                                    </div>
                                  </v-col>
                              </v-row>
                          </v-col>
                      </v-row>
                  </v-card>

                  <v-card  class="mt-5">
                      <v-row>
                          <v-col cols="8" sm="8">
                            <div style="color:#022D41" class="ml-10 mt-2">
                              <div class="title mb-2">ROSALES SPA</div>
                              <div class="subtitle-2 mb-2">Se busca carpintero con experiencia</div>
                              <div class="subtitle-2 mb-2">Comuna: Las Condes, RM</div>
                              <div class="subtitle-2">Fecha: 20/12/20</div>
                            </div> 
                          </v-col>
                          <v-col  cols="4" sm="4">
                              <v-row >
                                  <v-col>
                                <div class="mr-5 mt-2 text-end">
                                  <EnviarPostulacion/>
                                </div>
                                  </v-col>
                              </v-row>
                          </v-col>
                      </v-row>
                  </v-card>

                  <v-card class="mt-5">
                      <v-row>
                          <v-col cols="8" sm="8">
                            <div style="color:#022D41" class="ml-10 mt-2">
                              <div class="title mb-2">MARTINEZ Y FAMILIA SPA</div>
                              <div class="subtitle-2 mb-2">Se busca carpintero con experiencia</div>
                              <div class="subtitle-2 mb-2">Comuna: Las Condes, RM</div>
                              <div class="subtitle-2">Fecha: 20/12/20</div>
                            </div> 
                          </v-col>
                          <v-col cols="4" sm="4">
                              <v-row >
                                  <v-col>
                                <div class="mr-5 mt-2 text-end">
                                  <ErrorPostulacion/>
                                </div>
                                  </v-col>
                              </v-row>
                          </v-col>
                      </v-row>
                  </v-card>
              </v-col>
          </v-row>


</v-container>
</template>

<script>
  import ErrorPostulacion from '../Empleado/Botones avisos/ErrorPostualcion'
  import EnviarPostulacion from '../Empleado/Botones avisos/EnviarPostulacion'
  import { getUsersLaboral } from '../../../Helpers/api/user'
  export default {
    components:{EnviarPostulacion,ErrorPostulacion},
    name: 'Perfiles',
    data () {
      return {
        dialog:false,
        users: []
      }
    },
    mounted(){
      this.getUsers()
    },
    methods: {
      getUsers(){
        const self = this
        getUsersLaboral()
          .then(function(response){
            self.users = response.users
            console.log("self.users: ", self.users)
          })
          .catch(function(error){
            console.log(error)
          })
      }
      
    }
  }
</script>

