<template>
<v-container class="mt-16"  fluid >
<v-row>
  <v-col>
    <div class="mb-5">
    <v-img
			alt="SuPega Logo"
			class="ma-auto"
			contain
			:src="require('../../../assets/imgs/logo-big.svg')"
			transition="scale-transition"
			max-width="150"
				/>
</div>        
  </v-col>
</v-row>
    <v-form 
  
	ref="form"
	v-model="valid"
	lazy-validation
	class="d-flex flex-column flex-grow-1 mx-auto"
	style="max-width: 400px;width: 100%;"
	>
  <div class="rounded-lg mb-5" style="border:solid 1px;">
	<v-text-field
	v-model="email"
	background-color="white"
    style="background-color:white"
	:rules="emailRules"
	color="#022D41"
	hide-details="auto"
	class="login-inputs  mb-4"
	></v-text-field>
</div>

<div class="rounded-lg mb-2" style="border:solid 1px; height:60px">
	<v-text-field
		v-model="userPassword"
		:rules="passwordRules"
		:type="showPass ? 'text' : 'password'"
		color="white"
		hide-details="auto"
		class="login-inputs"
		@click:append="showPass = !showPass"
	></v-text-field>
</div>
    <v-tab  class="mt-5 ml-5">
		<v-btn class="rounded-pill" color="#D3E02F"  block depressed :loading="loadingLogin" @click="loginUser">
			<p class="mb-0 body-1 font-weight-bold text-capitalize text-primary">Ingresar</p>
		</v-btn>
	</v-tab>

	</v-form>
  <div>
  <p class="text-center mt-5">¿Olvidaste tu contraseña?</p>
  </div>
  <v-row justify="center">
    <v-col cols="6" sm="2">
		<router-link to="/NuevoRegistro">
        <v-btn class="rounded-pill" v-on:click="registrar =!registrar" color="#022D41" outlined dark block depressed >
        <p class="mb-0 body-1 font-weight-bold text-capitalize text-primary">Registrarse</p>
	</v-btn>
	</router-link> 
    </v-col>
  </v-row>

 

</v-container>


</template>

<script>
	import {login} from '../../../Helpers/api/user.js'
	export default {
		name: 'ModalLogin',
		data: () => ({
			valid: true,
			loadingLogin: false,
			showPass: false,
			email: '',
			emailRules: [
				v => !!v || 'Email es obligatorio',
				v => /.+@.+\..+/.test(v) || 'Email debe ser válido',
			],
			userPassword: '',
			passwordRules: [
				v => !!v || 'Contraseña es requerida'
			],
		}),
		methods: {
			validate () {
				this.$refs.form.validate()
			},
			loginUser () {
				if( this.$refs.form.validate() ){
					const self = this
					this.loadingLogin = true
					login(this.email, this.userPassword)
						.then(function () {
							self.$router.push({name: 'PreLogin'})
							self.$store.commit('setModalLogin', false)
							self.loadingLogin = false
						})
						.catch(function (error) {
							console.log(error)
							self.loadingLogin = false
						})
				}
			}
		}
	}
</script>
<style>
input:-webkit-autofill {
    -webkit-box-shadow:0 0 0 50px white inset; /* Change the color to your own background color */
    -webkit-text-fill-color: #333;
}
 
</style>

