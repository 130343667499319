<template>
	<v-container fluid class="pa-16 dflex justify-center align-center fill-height"
			style="background-color: #022D41;">
		<div class="py-8 px-12 modal-login-box dflex justify-center align-center">
			<v-img
				alt="SuPega Logo"
				class="ma-auto"
				contain
				:src="require('../../assets/imgs/logo-big.svg')"
				transition="scale-transition"
				max-width="150"
			/>
			<div class="mt-6">
				<v-form
					ref="form"
					v-model="valid"
					lazy-validation
					class="d-flex flex-column flex-grow-1"
					style="max-width: 400px;width: 100%;"
				>
					<v-text-field
						v-model="email"
						label="Correo"
						:rules="emailRules"
						color="#022D41"
						hide-details="auto"
						class="login-inputs mb-4"
					></v-text-field>
					<v-text-field
						v-model="userPassword"
						label="Contraseña"
						:rules="passwordRules"
						:type="showPass ? 'text' : 'password'"
						:append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
						color="#022D41"
						hide-details="auto"
						class="login-inputs"
						@click:append="showPass = !showPass"
					></v-text-field>
				</v-form>
				<v-row class="text-center my-4">
					<v-col cols="12" md="6" class="d-flex flex-column align-center justify-center">
						<v-btn color="#D3E02F" dark block depressed :loading="loadingLogin" @click="loginUser">
							<p class="mb-0 body-1 font-weight-bold text-capitalize text-primary">Ingresar</p>
						</v-btn>
					</v-col>
					<v-col cols="12" md="6" class="d-flex flex-column align-center justify-center">
						<v-btn color="#022D41" outlined dark block depressed :disabled="loadingLogin">
							<p class="mb-0 body-1 font-weight-bold text-capitalize text-primary">Registrarse</p>
						</v-btn>
					</v-col>
				</v-row>
				<v-row class="text-center">
					<v-col cols="12" class="d-flex flex-column align-center justify-center">
						<v-btn color="#808080" dark block text depressed :disabled="loadingLogin">
							<p class="mb-0 body-1 font-weight-regular text-capitalize">Recuperar contraseña</p>
						</v-btn>
					</v-col>
				</v-row>
			</div>
		</div>
	</v-container>
</template>

<script>
	import {login} from '../../Helpers/api/user'
	export default {
		name: 'Login',
		data: () => ({
			valid: true,
			loadingLogin: false,
			showPass: false,
			email: '',
			emailRules: [
				v => !!v || 'Email es obligatorio',
				v => /.+@.+\..+/.test(v) || 'Email debe ser válido',
			],
			userPassword: '',
			passwordRules: [
				v => !!v || 'Contraseña es requerida'
			],
		}),
		methods: {
			validate () {
				this.$refs.form.validate()
			},
			loginUser () {
				if( this.$refs.form.validate() ){
					const self = this
					login(this.email, this.userPassword)
						.then(function () {
							self.$router.push({name: 'Panel'})
							self.$store.commit('setModalLogin', false)
							self.loadingLogin = false
						})
						.catch(function (error) {
							console.log(error)
							self.loadingLogin = false
						})
				}
			}
		}
	}
</script>
