<template>
	<div>
		<v-app-bar app color="#EFF3F2" flat height="70">
			<v-container class="d-flex align-center">
				<v-app-bar-nav-icon v-if="$vuetify.breakpoint.mobile" @click.stop="drawerOpen = !drawerOpen"></v-app-bar-nav-icon>
				<v-img
					alt="SuPega Logo"
					class="shrink mr-2"
					contain
					:src="require('../../assets/imgs/logo.svg')"
					transition="scale-transition"
				/>
				<div class="d-flex justify-space-around flex-grow-1 ml-sm-16" v-if="!$vuetify.breakpoint.mobile">
					<v-btn
						text
						color="#022D41"
						@click="$vuetify.goTo('#videocv')"><p class="mb-0 subtitle-1 font-weight-regular">VIDEO CV</p></v-btn>
					<v-btn
						text
						color="#022D41"
						@click="$vuetify.goTo('#videoent')"><p class="mb-0 subtitle-1 font-weight-regular">VIDEO ENTREVISTA</p></v-btn>
					<v-btn
					rounded
					color="#022D41"
					@click="toLogin()"
					dark><p class="mb-0 body-2 font-weight-regular text-capitalize px-2 text-secondary">Ingresar</p></v-btn>
				</div>
			</v-container>
		</v-app-bar>
		<Drawer :drawerOpen="drawerOpen" :changeDrawerStatus="changeDrawerStatus"/>
	</div>
</template>
<script>

import Drawer from './Drawer'
export default {
	name: 'Header',
	components: { Drawer },
	data: () => ({
		drawerOpen: false
	}),
	methods: {
		changeDrawerStatus(status){
			this.drawerOpen = status
		},
		toLogin(){
			if(this.$store.state.isAuthenticated){
				this.$router.push({name: 'PreLogin'})
			}
			else{
				this.$store.commit('setModalLogin', true)
			}
		}
	}
};
</script>
