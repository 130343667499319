<template>
	<v-container
		fluid
		class="d-flex align-center pa-0 container-block-background landingbg"
		style="background-color: #EFF3F2;">
		<v-container>
			<v-row class="text-center">
				<v-col cols="12" md="6" class="d-flex flex-column align-center justify-center">
					<v-img
						alt="SuPega Logo"
						class="shrink mr-2"
						contain
						:src="require('../../assets/imgs/logo-big.svg')"
						transition="scale-transition"
						max-width="220"
					/>
					<p class="my-8 text-primary" style="font-size: 1.2rem;line-height: 1.5rem;">El portal que permite conseguir al colaborador que estás buscando a través del sistema más moderno.</p>

					<v-btn x-large rounded color="#022D41" dark class="px-16" @click="toLogin()">
						<p class="mb-0 title font-weight-regular text-capitalize text-secondary">Ingresar</p>
					</v-btn>
				</v-col>
				<v-col cols="12" md="6">
				</v-col>
			</v-row>
		</v-container>
	</v-container>
</template>

<script>
	export default {
		name: 'HomeLanding',
		data: () => ({
		}),
		methods: {
			toLogin(){
				if(this.$store.state.isAuthenticated){
					this.$router.push({name: 'PreLogin'})
				}
				else{
					this.$store.commit('setModalLogin', true)
				}
			}
		}
	}
</script>


