<template>
<div :class="!$vuetify.breakpoint.mobile ? '':'d-none d-md-flex'">
	<!--  -->
	<v-container
	class="pb-0"
    style="max-width: 300px;"
	>
		<v-list-item style="background-color:white" class="py-8 dflex justify-center">
			<v-img
				alt="SuPega Logo"
				contain
				:src="require('../../assets/imgs/logo-big.svg')"
				transition="scale-transition"
				max-width="100"
			/>
		</v-list-item>

		<div style="background:white">
		<v-row>
			<v-col class="text-center ">
				<v-container style="color:#022D41">
				<div class="title">Hazte Premiun</div>
				<div class="subtitle-2">Cancelando 5.000$ al mes</div>
				<div class="subtitle-2">Puedes salir en todas las Búsquedas de captadores</div>
				</v-container>
			</v-col>
		</v-row>
		</div>
		<v-divider></v-divider>

		<div >
			<v-row>
				<v-col class="pt-0">
					<a href="https://sosclick.cl/">
					<v-img
					
					:src="require('../../assets/imgs/test.png')"
					/>
					</a>
				</v-col>
			</v-row>
			<v-row>
				<v-col class="pt-0">
					<a href="https://www.iduam.com/">
					<v-img
					:src="require('../../assets/imgs/test2.png')"
					/>
					</a>
				</v-col>
			</v-row>
		</div>
	</v-container>
</div>	
</template>

<script>

	export default {
		name: 'PanelAnuncio',

		data () {
			return {
				
				
			}
		}
	}
</script>