<template>
      <v-container fluid style="background:#eff3f2">
          <v-row>
              <v-col cols="12" sm="12" class="text-center" >
                  <p class="display-1">Perfil Laboral</p>
              </v-col>
          </v-row>
          <v-row v-if="userlaboral && userlaboral.video" justify="center" class="fill-height" style="min-height: 320px;">
            <v-col cols="6">
              
            <video-player
            :style="!$vuetify.breakpoint.mobile ? '':''"
            :class="!$vuetify.breakpoint.mobile ? 'video-player-box vjs-big-play-centered':'video-player-box vjs-big-play-centered'"
							ref="videoPlayer"
							:options="{
								
								language: 'es',
								sources: [{
									type: 'video/mp4',
									src: 'http://201.238.254.93:8000/api/workers/video' + userlaboral.video
								}]
							}"
							:playsinline="true">
						</video-player>
            
            <v-btn rounded small block color="#d3e02f" class="my-4 subtitle-2" @click="dialog = true">Grabar nuevo video</v-btn>
            </v-col>
          </v-row>

          <v-row v-if="!loading && ( !userlaboral || (userlaboral && !userlaboral.video) )">
              <v-col cols="12">
                <div class="text-center"> 
                  <v-btn style="width: 50%;height: 25vh;background:white" @click="dialog = true">
                    <v-icon style="font-size:100px;">mdi-video</v-icon>
                  <p class="mb-0 caption">Grabar nuevo video</p>
                  </v-btn>
                </div>
              </v-col>
          </v-row>

          <v-dialog :width="!$vuetify.breakpoint.mobile ? '60%':'100%'"   v-model="dialog">
            <v-container style="background:#eff3f2">  
              <div :class="!$vuetify.breakpoint.mobile ? '':'ml-3'">
                <v-btn icon class="mt-5" @click="dialog = false"><v-icon :style="!$vuetify.breakpoint.mobile ? 'font-size: 4vh':'font-size: 6vh'">mdi-close</v-icon></v-btn>
              </div>
          <v-row>
            <v-col class="12">
              <div  class="text-center">

                  <br>
                  <video controls autoplay playsinline ref="video" :style="!$vuetify.breakpoint.mobile ? '':'width:90%'" height="450" width="700"></video>
                </div>

                  <div class="text-center mt-5">
                    <div v-if="blobRecorderVideo">
                    <p class="mb-0 text-center">Si estás conforme con este video puedes subirlo presionando el botón a continuación</p>
                    <v-btn rounded color="#d3e02f" class="mb-2 mt-2"  @click="uploadRecordedVideo">Enviar video</v-btn>
                  </div>

                  <div class="text-center mt-5">
                    <p class="subtitle-1">Escribe una guía de lo deseas decir en el tu video perfil en este campo de texto.</p>
                  </div>
                  <v-textarea
                  :style="!$vuetify.breakpoint.mobile ? 'width:50%':'width:100%'"
                  class="mx-auto"
                  height="90"
                  background-color="white"
                  outlined
                  maxlength="120"
                  counter
                  placeholder="Escribir guía"
                  />

                  <div>
                  <v-btn outlined rounded  class="mb-2 subtitle-2" id="btn-start-recording" :disabled="disabled" @click="startRec">Comenzar a grabar</v-btn>
                   <v-btn
                  rounded
                  :class="!$vuetify.breakpoint.mobile ? 'ml-5 mb-2':'mb-2'"
                  color="#FD2A22"
                  style="color:white"
                   id="btn-stop-recording"
                  :disabled="!disabled"
                   @click="stopRec"
                  >Terminar Grabación</v-btn>
                  </div>
                
              </div>
            </v-col>
          </v-row>
          </v-container>
          </v-dialog>

    <v-snackbar
        v-model="snackbar"
        :multi-line="multiLine"
        >
        {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="#d3e02f"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>

          
          <v-row :class="!$vuetify.breakpoint.mobile ? 'mt-9 ml-3':'mt-7'" >

              <v-col :style="!$vuetify.breakpoint.mobile ? '':''"  class="text-center" cols="12" sm="6">
                
                <div :class="!$vuetify.breakpoint.mobile ? 'text-end':'text-center'"   :style="!$vuetify.breakpoint.mobile ? 'margin-left:32%':''">
                <p class="title">Buscando Trabajo</p>
                </div>
              </v-col>
              <v-col :class="!$vuetify.breakpoint.mobile ? 'text-center':'text-end'"  cols="6" md="2">
                <p class="title">Activo</p> 
              </v-col>
              <v-col class=" pa-0" cols="6" sm="4">
                <v-switch color="success" v-model="userlaboral.status">
                </v-switch>
              </v-col>
          </v-row>
          <v-row justify="center" >
              <v-col cols="12" sm="6">
                  <v-select
                  v-model="userlaboral.area"
                  dense
                  label="Selecciona tu labor"
                  solo
                  >
                  </v-select>
              </v-col>
          </v-row>

          <v-row class="justify-center">
              <v-col cols="12" sm="6">
            <v-textarea
            outlined
            maxlength="120"
            counter
            v-model="userlaboral.descripcion"
            placeholder="Informacion personal"
          ></v-textarea>
              </v-col>
          </v-row>
          <v-row justify="center">
              <v-col class="text-center" cols="6">
                <v-btn
                class="subtitle-2"
                @click="dialog = false"  
                color="#d3e02f">Guardar Cambios</v-btn>
                
              </v-col>
          </v-row>
          
      </v-container>
</template>

<script>
import 'video.js/dist/video-js.css'
import { videoPlayer } from 'vue-video-player'
import { updateVideoLaboral } from '../../../Helpers/api/laboral'
import { getUser } from '../../../Helpers/api/user'
import RecordRTC from "recordrtc"
import { mapGetters } from "vuex"

export default {
  name: "VideoRecord",
  components: {videoPlayer},
  data() {
    return {
      loading: true,
      text: 'El video se ha cargado y subido con éxito.',
      multiLine: true,
      snackbar: false,
      dialog: false,
      recorder: null,
      disabled: false,
      blobRecorderVideo: null,
      userlaboral: {
        status: false,
        video: null,
        descripcion: null,
        area: []
      }
    };
  },
  computed: {
    ...mapGetters(["userData"])
  },
  watch:{
    userData: function(data){
      if(data){
        this.userlaboral = data.laboral
      }
    }
  },
  mounted() {
    this.userlaboral = this.$store.state.userData.laboral
    this.loading = false
  },
  methods: {
    
    startRec() {
      this.disabled = true;
      this.captureCamera(camera => {
        const video = this.$refs["video"];
        video.muted = true;
        video.volume = 0;
        video.srcObject = camera;
        this.recorder = RecordRTC(camera, {
          type: "video"
        });
        this.recorder.startRecording();
        this.recorder.camera = camera;

        this.disabled = true;
        
      });
    },
    stopRec() {
      this.disabled = false;
      this.recorder.stopRecording(this.stopRecordingCallback);
    },
    captureCamera(callback) {
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then(function(camera) {
          callback(camera);
        })
        .catch(function(error) {
          alert("Unable to capture your camera. Please check console logs.");
          console.error(error);
        });
    },
    stopRecordingCallback() {
      const video = this.$refs["video"];
      video.src = video.srcObject = null;
      video.muted = false;
      video.volume = 1;
      this.blobRecorderVideo = this.recorder.getBlob()
      video.src = URL.createObjectURL(this.blobRecorderVideo);

      this.recorder.camera.stop();
      this.recorder.destroy();
      this.recorder = null;
    },
    async uploadRecordedVideo(){
      const formData = new FormData()
      formData.append('video', this.blobRecorderVideo)
      const video = await updateVideoLaboral(formData)
      if(video.code === 200){
        await getUser()
        this.dialog = false
      }
      else{
        this.text = 'No se ha podido cargar correctamente el video, por favor intenta enviarlo nuevamente.'
      }
      this.snackbar = true
    }
  }
};
</script>
<style >

</style>
