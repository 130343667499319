<template>
  <v-container :class="!$vuetify.breakpoint.mobile ? '':'mr-3 pr-16'" fluid style="background:#eff3f2;">
    <v-row justify="center">
      <v-col class="ml-15" cols="12" sm="6" md="4" v-for="(user, index) in users" :key="index">
        <v-card rounded flat class="px-4" height="100%"
          @click="() => $store.commit('setModalUserInfo', { status: true, data: user })">
          <v-row >
            <v-col class="text-center" align-self="center" cols="4">
                <v-img :src="user.info.avatar"
                  :aspect-ratio="1" width="100%"
                  style="border-radius: 100%;"
                ></v-img>
            </v-col>
            <v-col class="text-start" cols="8">
              <div class="ml-5">
              <div class="title font-weight-bold">{{user.name}}</div>
              <div class="subtitle-2" v-for="(area,indexB) in user.laboral.area" :key="indexB">{{area}}</div>
              <div class="caption" style="line-height: 15px;">{{user.laboral.descripcion}}</div>
              <div class="caption mt-2">Comuna: {{user.info.comuna_data.comuna}}</div>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { getUsersLaboral } from '../../../Helpers/api/user'

  export default {
    name: 'Perfiles',
    data () {
      return {
        dialog:false,
        users: []
      }
    },
    mounted(){
      this.getUsers()
    },
    methods: {
      getUsers(){
        const self = this
        getUsersLaboral()
          .then(function(response){
            self.users = response.users
            console.log("self.users: ", self.users)
          })
          .catch(function(error){
            console.log(error)
          })
      }
      
    }
  }
</script>

<style>

</style>