<template>
  <v-container>
    <v-dialog  class="rounded-xl" width="30%" v-model="openDialog">
      <v-container style="background:white">
        <v-row justify="center">
          <v-col class="text-center" cols="12" sm="6">
            <h1>Agendar Entrevista</h1>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <p style="color:#022D41" class="subtitle-2 text-center">Por favor seleciones una fecha</p>
          </v-col>
        </v-row>
        <v-form>
        <div class="mx-auto rounded-lg" style="border:solid 1px;width:41%">
        <v-text-field
        v-model="HoraEntrevista"
        class="ml-2 pa-0"
        style="width:0%;height:2vw"
        
        type="datetime-local"
        />
        </div>
        
        </v-form>

        <v-row>
          <v-col>
            <p style="color:#022D41" class="subtitle-2 text-center mt-5">Por favor seleciones una hora</p>
          </v-col>
        </v-row>

        <v-form>
        <div class="mx-auto rounded-lg" style="border:solid 1px;width:41%;">
        <v-text-field
        v-model="HoraEntrevista"
        style="width:50%;height:2vw"
        class="mx-auto pb-0 pt-0"
        type="time"
        />
        </div>
        
        </v-form>

        <v-row>
          <v-col cols="6" class="text-end">
          <v-dialog close-delay="1" class="rounded-xl" width="25%" style="height: 20vw;"  v-model="dialog">
            <v-container style="background:#D3E02F">
              <v-row justify="center">
                <v-col class="text-center">
                  <v-icon style="font-size: 7vw;color:#022D41" class="mt-10">mdi-thumb-up-outline</v-icon>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <p style="font-size:1vw;color:#022D41" class="text-center title">Entrevista agendada con éxito</p>
                </v-col>
              </v-row>
            </v-container>
          </v-dialog>

          <v-btn @click="aceptarEntrevista()" width="150" height="40px" style="background-color:#D3E02F; color:#022D41" class="mt-3 subtitle-2" rounded>Aceptar</v-btn>
          </v-col>
          <v-col cols="6" class="text-start">
            <v-btn @click="closeDialog()" width="150" height="40px" style="background-color:white; color:#022D41" class="mt-3 subtitle-2" outlined rounded>Cancelar</v-btn>
          </v-col>
        </v-row>            

      </v-container>
    </v-dialog>
  </v-container>
</template>

<script>

export default {
  name: 'ModalSetEntrevista',
  props: ['openDialog', 'closeDialog'],
  components:{},
  data(){
    return{
        dialog1:false,
        dialog: false
    }
  },
  methods: {
    aceptarEntrevista(){
        this.dialog = true
        this.closeDialog()
    }
  }

}
</script>

<style>

</style>