<template>
	<v-dialog
		v-model="this.$store.state.modalLogin"
		persistent
		fullscreen
		content-class="modal-login dflex justify-center align-center text-center"
	>
		<v-container class="pa-16 dflex justify-center align-center fill-height" style="position: relative;">
			<v-btn
				icon
				dark
				style="position: absolute; right: 0; top: 5%;"
				@click="$store.commit('setModalLogin', false)"
			>
				<v-icon x-large>mdi-close</v-icon>
			</v-btn>
			<div style="height: auto;" class="py-8 px-12 modal-login-box dflex justify-center align-center">
				<v-img
					alt="SuPega Logo"
					class="ma-auto"
					contain
					:src="require('../../assets/imgs/logo-big.svg')"
					transition="scale-transition"
					max-width="150"
				/>
				<div class="mt-6">
					<div v-if="registrar">
						<Register />
					</div>
					<div v-else-if="true">
						<Login/>
					</div>
					<v-row class="text-center my-4">
						<v-col cols="12" md="12" class="d-flex flex-column align-center justify-center">
							<v-tab>
								<v-btn v-on:click="registrar =!registrar" color="#022D41" outlined dark block depressed >
									<p class="mb-0 body-1 font-weight-bold text-capitalize text-primary">Registrarse</p>
								</v-btn>
							</v-tab>	
						</v-col>
					</v-row>
				</div>				
			</div>
		</v-container>
	</v-dialog>
</template>

<script>
import Login from './Login.vue'
import Register from './Register.vue'
export default {
	components:{Login,Register},
	data (){
		return{
			iniciar: true,
			registrar: false,
		}
	}

}
</script>
