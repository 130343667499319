<template>
  <v-container>
    <v-dialog close-delay="1" class="rounded-xl" width="40%" v-model="dialog">
      <v-container style="background:#D3E02F">
        <v-row justify="center">
            <v-col class="text-center">
                <v-icon style="font-size: 7vw;color:#022D41" class="mt-10">mdi-thumb-up-outline</v-icon>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <p style="font-size:1vw;color:#022D41" class="text-center title">Postulación Enviada Con éxito</p>
            </v-col>
        </v-row>
      </v-container>
    </v-dialog>

    <v-btn
    tile
    style="background:#EFF3F2;font-size: 0.7vw"
    width="11vw"
    height="80"
    @click="dialog = true"
    >
    <div class="d-flex flex-column">
    <v-icon style="font-size:4vh">mdi-file-document-outline</v-icon>
    <space>Enviar Postulación</space>
    </div>
    </v-btn>
  </v-container>
</template>


<script>
export default {
    data(){
        return{
            dialog:false
        }
    }

}
</script>

<style>

</style>