<template>
  <v-container style="background:#EFF3F2;height:70vh;overflow-y: scroll;" fluid>
    <v-row style="float: right;">
      <v-col cols="auto">
          <div  v-for="(message, index) in messages" :key="index" class="rounded-lg d-flex flex-row-reverse" style="display:flex;">
              <div v-if="message.id_usuario === $store.state.userData.id">
                <p style="background:#D3E02F" class=" pl-2 pr-2 ml-2 pt-2 pb-2 subtitle-1 rounded-lg">{{message.message}} <br>
                 <span style="font-size:10px;float:right;" class="blue-grey--text text--lighten-1 Datecontent">{{message.date}}</span>
                </p> 
            </div>
            <div v-else class="lotiropalotrolao">
                <p style="background:white" class=" pl-2 pr-5 ml-2 pt-2 pb-2 subtitle-1 rounded-lg">{{message.message}} <br>
                  <span style="font-size:10px;float:right;" class="blue-grey--text text--lighten-2 Datecontent">{{message.date}}</span>
                </p> 
            </div>
          </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapGetters } from "vuex"
import firebase from 'firebase/app'
import moment from 'moment';

import 'firebase/database'
export default {
  name:'Body',
  data(){
    return{
      messages: [],
      salaChatID: null
    }
  },
  computed: {
    ...mapGetters(["idSalaChat"])
  },
  watch:{
    idSalaChat: function(data){
      console.log("data: ", data)
      if(this.salaChatID){
        this.messages = []
        this.stopChatRoom(this.salaChatID)
      }

      this.salaChatID = data

      if(this.salaChatID){
        this.startChatRoom()
      }

    }
  },
  methods:{
    date(){
      return moment().format('MMMM Do YYYY, h:mm:ss a')
    },
    stopChatRoom(sala){
      this.messages = []
      firebase.database(this.$store.state.primaryDB).ref('Chat/' + sala).off('child_added')
    },
    startChatRoom(){
      const self = this
      if(this.salaChatID){
        firebase.database(this.$store.state.primaryDB).ref('Chat/' + this.salaChatID).on('child_added',function(data){

          if( data.val() ){
            console.log("msg:", data.val())
            self.messages.push(data.val())
          }

        })
      }
    }
  }

}
</script>

<style>
.Datecontent{
margin:-11px 0px 0px 0px;
}
</style>