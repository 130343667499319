<template>
<div>
  <v-dialog width="50%" v-model="dialog" >
    <v-card-text  style="background: whitesmoke;">
      <v-container>
      <v-row>
        <v-col cols="12" style="color: #707070; ">
			<v-card-title>
				<!-- <v-img
					:src="require('../../../assets/imgs/profile-edit.svg')"
					width="70"
					height="70"
					max-width="70"
					max-height="70"
				></v-img> -->
        <v-icon color="#d3e02f" x-large>mdi-account-edit</v-icon>
				<span class="headline" style="color: #707070;"> Editar Perfil</span>
			</v-card-title>
          

    <v-form
       style="height:auto;"
       ref="form"
       v-if="user_profile"
       >
		<v-text-field
			v-model="user_profile.address"
			:rules="[rules.required]"
			label="Dirección"
			placeholder="Dirección"
			validate-on-blur
			dense
			class="mb-2"
		></v-text-field>

       <v-text-field
		v-model="user_profile.email"
		:rules="[rules.required, rules.email]"
		label="Email"
		placeholder="Email"
		validate-on-blur
		dense
		class="mb-2"
       ></v-text-field>

       <v-text-field
		v-model="user_profile.first_name"
		:rules="[rules.required]"
		label="Nombre"
		placeholder="Nombre"
		validate-on-blur
		dense
		class="mb-2"
      ></v-text-field>

       <v-text-field
		v-model="user_profile.last_name"
		:rules="[rules.required]"
		label="Apellido"
		placeholder="Apellido"
		validate-on-blur
		dense
		class="mb-2"
      ></v-text-field>

		<v-text-field
			v-model="user_profile.phone_number"
			:rules="[rules.required, rules.phone]"
			label="Teléfono"
			placeholder="Teléfono"
			validate-on-blur
			dense
			class="mb-2"
		></v-text-field>

       <v-text-field
		v-model="user_profile.rut"
		:rules="[rules.required]"
		label="Rut/DNI"
		placeholder="Rut/DNI"
		validate-on-blur
		dense
		class="mb-2"
       ></v-text-field>			  	 	  	   		
	</v-form>

<v-row>
	<v-col class="mb-7" cols="12">
				<div style="position: relative;">
			<v-alert
				v-show="message.show"
				dense
				text
				:type="message.type"
				class="mb-0"
				style="position: absolute; width: 100%;"
			>
				{{message.message}}
			</v-alert>
		</div>
	</v-col>
</v-row>

	<v-card-actions>
				<div class="d-flex justify-center align-center mb-3 flex-wrap" style="width: 100%;">
					<v-btn
						color="#707070"
						text
						rounded
						outlined
						@click="dialog = false"
						style="width: 200px;"
						class="mx-2"
						:class="$vuetify.breakpoint.mobile ? 'mb-3':''"
						:block="$vuetify.breakpoint.mobile"
					>
						<p class="body-2 mb-0" >Cancelar</p>
					</v-btn>
					<v-btn
						color="#d3e02f"
						depressed
						rounded
						:loading="loading"
						dark
						@click="updateUserInfo"
						style="width: 200px;"
						class="mx-2 custom-button-disabled-sos"
						:block="$vuetify.breakpoint.mobile"
					>
						<p style="color:black;" class="body-2 mb-0">Guardar Cambios</p>
					</v-btn>
				</div>
				
			</v-card-actions>
			
</v-col>
</v-row>
</v-container>
</v-card-text>
</v-dialog>
<v-btn class="rounded-pill " width="200" height="80" style="background:#D3E02F" text @click="dialog = true"><v-icon>mdi-account-edit</v-icon>Editar Perfil</v-btn>

</div>
</template>

<script>
import { mapGetters } from "vuex"
import {paisesActivos, regionesActivas, comunasActivas,getUser, updateUserInfo} from '../../../Helpers/api/user.js'
import { format } from 'rut.js'
  
export default {
  name:'Editar',
data:() =>({
  menu:false,
  message:{
    show:false,
    type:'success',
    message:''},
  rules: {
  required: value => !!value || 'Requerido',
	phone: value => value && value.length === 11 || 'Debe tener 11 dígtos. Ej (56912345678)',
	email: value => {
		const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return pattern.test(value) || 'Email no es válido'}
    }, 

pin: '',
loading: false,
dialog:false,
user_profile: null,
// paises: [],
// regiones: [],
// comunas: [],
// items: [{text: 'Femenino', value: 2}, {text: 'Masculino', value: 1}],
}),
	computed: {
	...mapGetters(["userData"])
    },
    watch:{
			userData: function(data){
				console.log("userData: ", data)
				if(data){
					this.user_profile = {
						address: data.info.address,
						email: data.email,
						first_name: data.first_name,
						last_name: data.last_name,
						phone_number: data.phone_number,
						profile_photo: data.profile_photo,
						rut: data.info.rut ? format(data.info.rut) : null,						
					}
					console.log(data);
					this.getRegion(this.user_profile.pais)
				}
			}
    },
    mounted () {
			this.getPaises()
			if(this.$store.state.userData){
					this.user_profile = {
						address: this.$store.state.userData.address,
						email: this.$store.state.userData.email,
						first_name: this.$store.state.userData.first_name,
						last_name: this.$store.state.userData.last_name,
						phone_number: this.$store.state.userData.phone_number,
						profile_photo: this.$store.state.userData.profile_photol,
						rut: this.$store.state.userData.rut ? format(this.$store.state.userData.rut) : null,
					}
			}
		},
methods:{
  closeModal(){
				// this.$store.commit('setModalsStates', {editProfile: false})
      },
      changePais(value){
				this.userForm = Object.assign({}, this.user_profile, {region: null, comuna: null})
				this.getRegion(value)
			},
			changeRegion(value){
				this.userForm = Object.assign({}, this.user_profile, {comuna: null})
				this.getComunas(value)				
      },
      async updateUserInfo(){
				const valid = this.$refs.form.validate()
				if(valid){
					const update = await updateUserInfo(this.user_profile)
					console.log(update);
					console.log(update.status);
					if(update && update.status === 201){
						this.message = {
							show: true,
							type: 'success',
							message: 'Datos actualizados correctamente'
						}
						getUser()
					}
					else{
						this.message = {
							show: true,
							type: 'error',
							message: 'Ha ocurrido un error al intentar actualizar los datos. Por favor intenta nuevamente.'
						}
					}
					setTimeout(() => {
						this.message = {
							show: false,
							type: 'success',
							message: ''
						}
					}, 4000);
				}
			},
  async getPaises(){
		const paises = await paisesActivos()
				this.paises = paises.paises
	},
	async getRegion(pais_id){
		const regiones = await regionesActivas({pais_id})
			this.regiones = regiones.regiones
				if(this.userForm && this.userForm.region){ this.getComunas(this.userForm.region) }
				else{ this.comunas = [] }
			},
	async getComunas(region_id){
			const comunas = await comunasActivas({region_id})
				this.comunas = comunas.comunas
			}
}
}
</script>
